import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InventoryByProducts from './components/InventoryByProducts';

const inventoryByProducts = (props,{ t }) => (
  <Container>
    <InventoryByProducts {...props} />
  </Container>
);

inventoryByProducts.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(inventoryByProducts);
