import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react'

const DashBoardDataTable = (props) => {
    const tableFields = props.tableFields ? props.tableFields : [];

    const results = props?.results ?? [];
    const customBodyStyles = (row, col) => {
        if(col && col.bodyStyle && col.bodyStyle == "expiry")
        {
            return <div className='d-flex justify-content-center align-items-center gap-2'>
				{ row[col.searchKey]?.length >0 ? 
                    <span>
                        <FontAwesomeIcon icon={["fa", "exclamation-triangle"]} color={"red"} /> &nbsp;
                        <span>{row[col.searchKey]?.length}</span>
                    </span> : 
                    <span>
                        <FontAwesomeIcon icon={["fa", "check-circle"]} color={"green"} />
                    </span>
                }
            </div>
        }
        if(col && col.bodyStyle && col.bodyStyle == "title"){
            return <div className='d-flex justify-content-center align-items-center gap-2' style={{fontWeight:"600"}}>{row[col.searchKey]}</div>
        }
        
        if(col && col.bodyStyle  && col.bodyStyle == "missing"){
            return  <div className='d-flex justify-content-center align-items-center gap-2'>
            { row[col.searchKey]?.length >0 ? 
                <span>
                    <FontAwesomeIcon icon={["fa","ban"]} color={"#f59e0b"} /> &nbsp;
                    <span>{row[col.searchKey]?.length}</span>
                </span> : 
                <span>
                <FontAwesomeIcon icon={["fa", "check-circle"]} color={"green"} />
            </span>
            }
            </div>
        }
        else{
            return <div style={{textAlign : 'center'}}>{row[col.searchKey]}</div>
        }
    }
  return (
    <div>
        <DataTable 
        value={ results && results }
        resizableColumns
        columnResizeMode="expand"
        showGridlines
        paginator={false}
        scrollable
        stripedRows
        scrollHeight='20rem'
        rows={10}
        // selection={}
        >
        {
            /**@COLUMNS */
            tableFields.map((column,index) => ( column.show && <Column 
            field={column.searchKey} 
            header= {column.title}
            body={row => customBodyStyles(row,column)}
            />))
        }
        </DataTable>
    </div>
    )
}

export default DashBoardDataTable;