import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const AssetMaintenances = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Asset Maintenance");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        fieldName: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        value: "",
        label: "Facility",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "center",
        show: true,
        field: "facility",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Name",
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "category",
        fieldName: "category",
        type: "dropDown",
        placeholder: "Category",
        value: "",
        label: "Category",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Category",
        derivedValue: "category=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "category",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Machinery and Equipment",
            value: "Machinery and Equipment",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Electronics",
            value: "Electronics",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Office Supplies",
            value: "Office Supplies",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Tools and Instruments",
            value: "Tools and Instruments",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Furniture and Fixtures",
            value: "Furniture and Fixtures",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Healthcare Equipment",
            value: "Healthcare Equipment",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Vehicles",
            value: "Vehicles",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Energy and Utilities",
            value: "Energy and Utilities",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "IT and Telecommunications",
            value: "IT and Telecommunications",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Machinery and Equipment",
            value: "Machinery and Equipment",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Electronics",
            value: "Electronics",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Office Supplies",
            value: "Office Supplies",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Tools and Instruments",
            value: "Tools and Instruments",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Furniture and Fixtures",
            value: "Furniture and Fixtures",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Healthcare Equipment",
            value: "Healthcare Equipment",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Vehicles",
            value: "Vehicles",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Energy and Utilities",
            value: "Energy and Utilities",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "IT and Telecommunications",
            value: "IT and Telecommunications",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "category",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "quantity",
        fieldName: "quantity",
        type: "number",
        placeholder: "Quantity",
        value: "",
        label: "Quantity",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Quantity",
        derivedValue: "quantity=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "quantity",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "quantity",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Pending",
            value: "Pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Maintenance Done",
            value: "Maintenance Done",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Pending",
            value: "Pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Maintenance Done",
            value: "Maintenance Done",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maintainceDate",
        fieldName: "maintainceDate",
        type: "date",
        placeholder: "Maintenance Date",
        value: "",
        label: "Maintenance Date",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Maintenance Date",
        derivedValue: "maintainceDate=maintainceDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "maintainceDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "maintainceDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maintenanceDoneDate",
        fieldName: "maintenanceDoneDate",
        type: "date",
        placeholder: "Maintenance completed Date",
        value: "",
        label: "Maintenance completed Date",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Maintenance completed Date",
        derivedValue: "maintenanceDoneDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "maintenanceDoneDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "maintenanceDoneDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: "90px",
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: "90px",
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: "60px",
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "facility",
        fieldName: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        value: "",
        label: "Facility",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "category",
        fieldName: "category",
        type: "dropDown",
        placeholder: "Category",
        value: "",
        label: "Category",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "category=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "category",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Machinery and Equipment",
            value: "Machinery and Equipment",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Electronics",
            value: "Electronics",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Office Supplies",
            value: "Office Supplies",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Tools and Instruments",
            value: "Tools and Instruments",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Furniture and Fixtures",
            value: "Furniture and Fixtures",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Healthcare Equipment",
            value: "Healthcare Equipment",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Vehicles",
            value: "Vehicles",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Energy and Utilities",
            value: "Energy and Utilities",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "IT and Telecommunications",
            value: "IT and Telecommunications",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Machinery and Equipment",
            value: "Machinery and Equipment",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Electronics",
            value: "Electronics",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Office Supplies",
            value: "Office Supplies",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Tools and Instruments",
            value: "Tools and Instruments",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Furniture and Fixtures",
            value: "Furniture and Fixtures",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Healthcare Equipment",
            value: "Healthcare Equipment",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Vehicles",
            value: "Vehicles",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Energy and Utilities",
            value: "Energy and Utilities",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "IT and Telecommunications",
            value: "IT and Telecommunications",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "quantity",
        fieldName: "quantity",
        type: "number",
        placeholder: "Quantity",
        value: "",
        label: "Quantity",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "quantity=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "quantity",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Pending",
            value: "Pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Maintenance Done",
            value: "Maintenance Done",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Pending",
            value: "Pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Maintenance Done",
            value: "Maintenance Done",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "maintainceDate",
        fieldName: "maintainceDate",
        type: "date",
        placeholder: "Maintenance Date",
        value: "",
        label: "Maintenance Date",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "maintainceDate=maintainceDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "maintainceDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "maintenanceDoneDate",
        type: "date",
        placeholder: "Maintenance completed Date",
        label: "Maintenance completed Date",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "maintenanceDoneDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "maintenanceDoneDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        selectToday : true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: "90px",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: "90px",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        fieldName: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        value: "",
        label: "Facility",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: "",
        isAddToIndex: "",
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "center",
        show: true,
        field: "facility",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Name",
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "category",
        fieldName: "category",
        type: "dropDown",
        placeholder: "Category",
        value: "",
        label: "Category",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Category",
        derivedValue: "category=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "category",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Machinery and Equipment",
            value: "Machinery and Equipment",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Electronics",
            value: "Electronics",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Office Supplies",
            value: "Office Supplies",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Tools and Instruments",
            value: "Tools and Instruments",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Furniture and Fixtures",
            value: "Furniture and Fixtures",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Healthcare Equipment",
            value: "Healthcare Equipment",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Vehicles",
            value: "Vehicles",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Energy and Utilities",
            value: "Energy and Utilities",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "IT and Telecommunications",
            value: "IT and Telecommunications",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Machinery and Equipment",
            value: "Machinery and Equipment",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Electronics",
            value: "Electronics",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Office Supplies",
            value: "Office Supplies",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Tools and Instruments",
            value: "Tools and Instruments",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Furniture and Fixtures",
            value: "Furniture and Fixtures",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Healthcare Equipment",
            value: "Healthcare Equipment",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Vehicles",
            value: "Vehicles",
            color: "dark",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Energy and Utilities",
            value: "Energy and Utilities",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "IT and Telecommunications",
            value: "IT and Telecommunications",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "category",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "quantity",
        fieldName: "quantity",
        type: "number",
        placeholder: "Quantity",
        value: "",
        label: "Quantity",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Quantity",
        derivedValue: "quantity=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "quantity",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "quantity",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        options: [
          {
            label: "Pending",
            value: "Pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Maintenance Done",
            value: "Maintenance Done",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Pending",
            value: "Pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Maintenance Done",
            value: "Maintenance Done",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maintainceDate",
        fieldName: "maintainceDate",
        type: "date",
        placeholder: "Maintaince Date",
        value: "",
        label: "Maintaince Date",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Maintaince Date",
        derivedValue: "maintainceDate=maintainceDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "maintainceDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "maintainceDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maintenanceDoneDate",
        fieldName: "maintenanceDoneDate",
        type: "date",
        placeholder: "Maintaince Done Date",
        value: "",
        label: "Maintaince Done Date",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Maintaince Done Date",
        derivedValue: "maintenanceDoneDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "maintenanceDoneDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        isMultipleRequired: "",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "maintenanceDoneDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: "90px",
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: "90px",
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: "120px",
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: "120px",
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: "60px",
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.assetMaintenances}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  console.log('isEditPermission', isEditPermission);
  const actionTypes = [];

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={false}
          editRequired={isEditPermission}
          deleteRequired={false}
          viewRequired={isEditPermission}
          exportRequired={false}
          sample={true}
          printRequried={false}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={false}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.assetMaintenances}
          globalSearch={
            "Facility/Name/Category/Status/Maintenance Date/maintenance Done Date"
          }
          displayName="Asset Maintenance"
          type="Asset Maintenance"
          routeTo={apiCalls.assetMaintenances}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.assetMaintenances?.toLowerCase()}
          apiUrl={apiCalls.assetMaintenances}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="assetMaintenances"
          apiUrl={apiCalls.assetMaintenances}
        />
      ) : null}
    </span>
  );
};

export default AssetMaintenances;
