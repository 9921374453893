import React, { useEffect, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import fetchMethodRequest from '../../../config/service';

const DoInputField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const disable = props.disable;
    const [inventoryQuantity, setInventoryQuantity] = useState();
    let watcher = props.item?.showCountMessage && props?.watch &&  props?.watchList ? props.watch(props.watchList) : null;
    let prePopulate = props.item?.prepopulateField && props.item?.prepopulateFieldAccessName && props?.watch ? props.watch(props.item?.prepopulateField) : null;
    useEffect(() => {
      getCurrentInvenroryCount()
    }, [watcher])

    useEffect(() => {
      if(prePopulate && prePopulate[props.item?.prepopulateFieldAccessName] && props.setValue) {
        props.setValue(props.field?.name,prePopulate[props.item?.prepopulateFieldAccessName]);
      }
    },[prePopulate])
    

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

      const getCurrentInvenroryCount =() => {
        if(props.watchList?.length > 0) {
          let requestApproval = props.watchList.every( field => props.getValues(field));
          if(requestApproval){
            let query = '';
            props.watchList?.map( field => {
              let key = '_id';
              if(field === 'lotId'){
                key = 'lotId';
              }
              query +=field + '='+ (props.getValues(field)[key] ? props.getValues(field)[key] : props.getValues(field)) + '&'
          })
            fetchMethodRequest('GET', `inventories/currentquantity?${query}`).then( response => {
              console.log('respone', response);
              if(response && response['currentquantity'] && response['currentquantity']?.productQuantityBeingAdded){
                setInventoryQuantity(response['currentquantity']?.productQuantityBeingAdded);
              }
            })
          }
          
        }
      }

    return (
        <div className="flex flex-column">
             <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <InputText 
            type={type}
            min={props.minValue}
            onKeyDown={(e) => {
              // Check if the type is 'number' before preventing the 'e' key
              if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
                e.preventDefault();
              }
            }} 
            value={field.value}
            id={id} 
            name={name} 
            placeholder={placeholder}
            aria-describedby="username-help" 
            disabled={disable}
            onChange={(e) => field.onChange(e.target.value)} /> 
            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
            {inventoryQuantity && props.item?.showCountMessage && <small className="text-primary">Your selected inventory currently has <b>{inventoryQuantity}</b> units available</small> }
        </div>
    )

}

export default DoInputField;