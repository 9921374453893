import React, { useEffect, useRef, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from '../../CommonModals/ConfirmationModal';
import actions from "redux-form/lib/actions";

// config file

const ExpirationItems = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  let getDataFromServerRef = useRef();
  const params = useParams()
  let filterQueryParam ;
  const query = new URLSearchParams(location.search);


  if(query.get('searchId') && query.get('searchName')){
    filterQueryParam = { searchId : query.get('searchId'), searchName : query.get('searchName')}
  }
  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Expiration Items");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "left",
        show: true,
        field: "facility",
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Cabinents",
        label: "Cabinents",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Cabinents",
        derivedValue: "cabinents=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "cabinents",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Racks",
        label: "Racks",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Racks",
        derivedValue: "racks=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "racks",
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Product Name",
        derivedValue: "productName=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "productName",
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Lot Id",
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "lotId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "productQuantityBeingAdded",
        type: "text",
        placeholder: "Quantity",
        label: "Quantity",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Quantity",
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "productQuantityBeingAdded",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        label: "Expiration Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Expiration Date",
        derivedValue: "expirationDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "expirationDate",
        showOrHideFields: [],
        fieldName: "expirationDate",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },{
        name: "status",
        type: "dropDown",
        placeholder: "status",
        label: "status",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Expired" ,value: "Expired", color: "danger" },
          { label: "Expiring Soon",value: "Expiring Soon", color: "warning" },
        ],
        filterElement: [
          { label: "Expired",value: "Expired", color: "danger" },
          { label: "Expiring Soon",value: "Expiring Soon", color: "warning" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "requiredInventory",
        type: "text",
        placeholder: "Required Inventory",
        label: "Required Inventory",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Required Inventory",
        derivedValue: "requiredInventory=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "requiredInventory",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "requiredInventory",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "left",
        disabled: true,
        show: false,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "left",
        disabled: true,
        show: false,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "left",
        show: false,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "left",
        show: false,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: false,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        show: true,
        width: 80,
        textAlign: "center",
        width: 80,
        minWidth :'100px',
        show: true,
        field: "addConsumeActions",
        header: "Actions",
        label: "ActionsButton",
        fieldType: "customType",
        customType:customType,
        actionDependentFunction: actionDependentFunction,
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "facility",
        type: "text",
        placeholder: "Select Facility",
        label: "Facility",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "noOfItems",
        type: "text",
        placeholder: "No Of Items",
        label: "No Of Items",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "noOfItems=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "noOfItems",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "text",
        placeholder: "Facility",
        label: "Facility",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "facility",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "storageUnit",
        type: "text",
        placeholder: "StorageUnit",
        label: "StorageUnit",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "StorageUnit",
        derivedValue: "storageUnit=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "storageUnit",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "storageUnit",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "noOfItems",
        type: "text",
        placeholder: "No Of Items",
        label: "No Of Items",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "No Of Items",
        derivedValue: "noOfItems=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "noOfItems",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "noOfItems",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Created",
        derivedValue: "created=created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Updated",
        derivedValue: "updated=updated=updated=updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  const consumeExpiredQuantity = () => {
    let request = Object.assign(rowData,{consumeType : "Expired" , type : 'sub'})
    delete request.created
    delete request.updated
    delete request.status
    fetchMethodRequest('POST',apiCalls.inventries,request).then((response) => {
      if (response && response.respCode) {
        closeConfirmationModal();
        getDataFromServerRef.current();
        showToasterMessage(response.respMessage, 'success');
      } else if (response && response.errorMessage) {
        showToasterMessage(response.errorMessage, 'error');
      }
    })
  }

  const closeConfirmationModal = () => {
    setOpenConfirmationModal(false);
  }

  const getConfirmatioinModal = () => {
    return (
      <ConfirmationModal
        openConfirmationModal={openConfirmationModal}
        closeConfirmationModal={closeConfirmationModal}
        confirm={() => consumeExpiredQuantity(rowData)}
        text={'Consume Expired Inventory'}
      />
    )
  }


  function customType (rowData,column,getDataFromServer){
    if(column.field === 'addConsumeActions' && rowData?.status === 'Expired') {
          return (
            <div className='row'>
              <div style={{display : 'flex', justifyContent:'space-around' }}>
              <div style={{ backgroundColor : 'red'}} className="custom-acton-button">
              <FontAwesomeIcon
                    className='genderIconAlignment'
                    color='white'
                    icon='minus'
                    title='Remove Quantity from Inventory'
                    data-toggle="tool-tip"
                    style={{ color: '#fff', width: '20', cursor: 'pointer' }}
                    onClick={() =>  {
                      getDataFromServerRef.current =  getDataFromServer
                      setRowData(rowData)
                      setOpenConfirmationModal(true);
                      }
                    } />
              </div>
              </div>
            </div >
          )
    }
  }

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.expirationItems}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  function actionDependentFunction(rowData,col) {
    return rowData.status === "Expired"
  }

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
      {
        name: "Consume",
        action: saveDataToServer,
        options: [
          {
            label: "Consume",
            field: "status",
            value: "Expired",
            show: rolePermission && rolePermission.type == "Edit" ? true : false,
            multiple: true,
          },
        ],
      },
  ];

  return (
    <span>
      {openConfirmationModal && rowData && getConfirmatioinModal()}
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.expirationItems}
          globalSearch={"Facility/Cabinets/Racks/Product Name/LotId"}
          displayName="Expiration Items"
          type="ExpirationItems"
          routeTo={apiCalls.expirationItems}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.expirationItems.toLowerCase()}
          apiUrl={apiCalls.expirationItems}
          selectedId={params.id}
          sidebarRender={true}
          filterQueryParam = {filterQueryParam}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="expirationItems"
          apiUrl={apiCalls.expirationItems}
        />
      ) : null}
    </span>
  );
};

export default ExpirationItems;
