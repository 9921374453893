import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CountDisplay = (props) => {
	let loginRole = localStorage.getItem("loginCredentials");
	loginRole = JSON.parse(loginRole);
	const [itemsList] = useState([
		{ displayName: 'Inventories', route: '/inventory', color: '#39cccc', key: 'inventories', icon : 'warehouse' },
		{ displayName: 'Missing Items', route: '/missingItems', color: '#d81b60', key: 'missingItems',icon : 'ban' },
		{ displayName: 'Expired items', route: '/expirationItems?searchId=Expired&searchName=status', color: '#605ca8', key: 'expiredItems', icon : 'exclamation-triangle'  },
		{ displayName: 'Need to order', route: '/orders?searchId=Need To Order&searchName=status', color: '#ff851b', key: 'needToOrder', icon : 'store'  }
	]);

	return (
		<Container className='px-0'>
			<Row>
				{itemsList.map((item, index) => (
					<Col md={12} xl={3} lg={6} xs={12} key={index}>
						<Link to={item.route} style={{ textDecoration: 'none' }}>
							<Card>
								<CardBody className="dashboard__card-widget d-flex justify-content-between" style={{ backgroundColor: item.color }}>
									<div>
										<div className="card__title">
											<h5 className="bold-text text-white">{item.displayName}</h5>
										</div>
										<div className="dashboard__total text-white">
											<TrendingUpIcon className='text-white' />
											<p className="dashboard__total-stat text-white">
												{props?.dashboardData?.[item.key]}
											</p>
										</div>
									</div>
									<div className="card-icon">
										<FontAwesomeIcon icon={["fa", item.icon]} className="menu-icon" />
									</div>
								</CardBody>
							</Card>
						</Link>
					</Col>
				))}
			</Row>
		</Container >
	);
}

export default CountDisplay;
