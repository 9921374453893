import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { Button } from "primereact/button";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// config file

const Inventries = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState()
  let getDataFromServerRef = useRef();
  const [ openFormModal, setopenFormModal] = useState(false);
  const [actionApiUrls, setActionApiUrls] = useState('');
  const [actionModalHeaders, setActionModalHeaders] = useState('');
  const [customActionType, setCustomActionType] = useState('');
  const [tableActionType, setTableActionType] = useState('');

  const params = useParams();

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [];

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Inventory");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Lot Id",
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "lotId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "left",
        show: true,
        field: "facility",
        showOrHideFields: [],
        fieldName: "facilitySearch",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Cabinents",
        label: "Cabinents",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Cabinents",
        derivedValue: "cabinents=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        // fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        textAlign: "left",
        show: false,
        field: "cabinents",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Racks",
        label: "Racks",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Racks",
        derivedValue: "racks=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        // fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        textAlign: "left",
        show: false,
        field: "racks",
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Product Name",
        derivedValue: "productName=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "Inventory",
        populteFields: [],
        displayFields: ["productNameSearch"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "productName",
        fieldType:"relateAutoComplete",
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "text",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "productQuantityBeingAdded",
        type: "text",
        placeholder: "Product Quantity Being Added",
        label: "Product Quantity Being Added",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Quantity",
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "center",
        show: true,
        field: "productQuantityBeingAdded",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'number',
        showZero:true,
      },
      {
        name: "needToOrder",
        type: "text",
        placeholder: "Need To Order",
        label: "Need To Order",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Need To Order",
        derivedValue: "needToOrder=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "needToOrder",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "needToOrder",
        fieldType:"number",
        showOrHideFields: [],
        mobile: true,
        showZero:true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "minimumThreshold",
        type: "text",
        placeholder: "Minimum Threshold",
        label: "Minimum Threshold",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Min Threshold",
        derivedValue: "minimumThreshold=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "minimumThreshold",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "minimumThreshold",
        showOrHideFields: [],
        mobile: true,
        showZero:true,
        fieldType:"number",
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "maximumThreshold",
        type: "text",
        placeholder: "Maximum Threshold",
        label: "Maximum Threshold",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Max Threshold",
        derivedValue: "maximumThreshold=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "maximumThreshold",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "maximumThreshold",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showZero:true,
        fieldType: "number"
      },
      {
        name: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        label: "Expiration Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Expiration Date",
        derivedValue: "expirationDate=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "expirationDate",
        showOrHideFields: [],
        fieldName: "expirationDate",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: false,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: false,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: false,
        textAlign: "center",
        width: 80,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        show: true,
        field: "addToOrderList",
        header: "Order",
        label: "ActionsButton",
        fieldType: "customType",
        customType:customType,
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
      },
      
      {
        show: true,
        width: 80,
        textAlign: "center",
        width: 80,
        minWidth :'100px',
        show: true,
        field: "addConsumeActions",
        header: "Actions",
        label: "ActionsButton",
        fieldType: "customType",
        customType:customType,
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "facility=facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customItemTemplate : true,
        customTemplateName : 'facilityEmail'
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Select Cabinet",
        label: "Cabinets",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "cabinents=cabinents=cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Select Rack",
        label: "Racks",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'cabinents',
        hasDependency: "true",
      },
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Select Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "productName=productName=productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
        prepopulateField : 'productName',
        prepopulateFieldAccessName : 'description',
      },
      {
        name: "productQuantityBeingAdded",
        type: "text",
        placeholder: "Product Quantity Being Added",
        label: "Product Quantity Being Added",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        label: "Expiration Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "expirationDate=expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "date",
        show: true,
        showOrHideFields: [],
        fieldName: "expirationDate",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "requiredInventory",
        type: "text",
        placeholder: "Required Inventory",
        label: "Required Inventory",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "requiredInventory=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "requiredInventory",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const getActionFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Select Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customItemTemplate : true,
        customTemplateName : 'facilityEmail',
        disable : true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Select Cabinet",
        label: "Cabinets",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: apiCalls.inventries+"/cabinetsbyproductandlot",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
        customResopnseKey : 'cabinetsbyproductandlot',
        customQuery : ['facility','productName','lotId'],
        excludeInPlus : true,
      },
      
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Select Cabinet",
        label: "Cabinets",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: 'cabinets',
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
        excludeInMinus : true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Select Rack",
        label: "Racks",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: apiCalls.inventries+'/racksbyproductandlot',
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'cabinents',
        hasDependency: "true",
        customResopnseKey : 'racksbyproductandlot',
        customQuery : ['facility','productName','lotId','cabinents'],
        excludeInPlus : true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Select Rack",
        label: "Racks",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: 'racks',
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'cabinents',
        hasDependency: "true",
        excludeInMinus : true,
      },
      {
        name: "productQuantityBeingAdded",
        type: "number",
        placeholder: "Quantity",
        label: "Quantity",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeSetValue : true,
        showCountMessage : true,
      },
      {
        name: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        label: "Expiration Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "date",
        show: true,
        showOrHideFields: [],
        fieldName: "expirationDate",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeInMinus : true,
      },
      
      {
        name: "consumeType",
        type: "dropDown",
        placeholder: "Consume Type",
        label: "Consume Type",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Consume Type",
        derivedValue: "consumeType=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "consumeType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Sold", value: "Sold", color: "success" },
          { label: "Expired", value: "Expired", color: "danger" },
          { label: "Damaged", value: "Damaged", color: "warning" },
          { label: "Others", value: "Others", color: "secondary" },
        ],
        filterElement: [
          { label: "Sold", value: "Sold", color: "success" },
          { label: "Expired", value: "Expired", color: "danger" },
          { label: "Damaged", value: "Damaged", color: "warning" },
          { label: "Others", value: "Others", color: "secondary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "consumeType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        excludeInPlus : true,
        value : 'Sold',
        dependent:[{"Others": ["note"]}],
        required : true,
      },
      {
        name: "note",
        type: "text",
        placeholder: "Note",
        label: "Note",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        required : true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeInPlus : true,
      },
    ];
  };

  const getOrderFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Select Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "product=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "product",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1018,
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1018,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "facility",
        searchApi: "facilities",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "productQuantityBeingAdded",
        type: "number",
        placeholder: "Current Quantity",
        label: "Current Quantity",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "quantity",
        type: "number",
        placeholder: "Quantity",
        label: "Quantity",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "quantity=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "quantity",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };
  const getMoveToRackFormFields = () => {
    return [
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Select Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Select Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customItemTemplate : true,
        customTemplateName : 'facilityEmail',
        disable : true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Select Cabinet",
        label: "Cabinets",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: apiCalls.inventries+"/cabinetsbyproductandlot",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
        customResopnseKey : 'cabinetsbyproductandlot',
        customQuery : ['facility','productName','lotId'],
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Select Rack",
        label: "Racks",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: apiCalls.inventries+'/racksbyproductandlot',
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'cabinents',
        hasDependency: "true",
        customResopnseKey : 'racksbyproductandlot',
        customQuery : ['facility','productName','lotId','cabinents'],
      },
      {
        name: "destinationCabinet",
        type: "relateAutoComplete",
        placeholder: "Select Destination Cabinet",
        label: "Destination Cabinet",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "destinationCabinet=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "destinationCabinet",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: 'cabinets',
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
      },
      {
        name: "destinationRack",
        type: "relateAutoComplete",
        placeholder: "Select Destination Rack",
        label: "Destination Rack",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: 'racks',
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'destinationCabinet',
        hasDependency: "true",
      },
    ];
  };
  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Facility",
        derivedValue: "facility=facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "Center",
        show: true,
        field: "facility",
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Cabinents",
        label: "Cabinents",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Cabinents",
        derivedValue: "cabinents=cabinents=cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "cabinents",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinet_cabinetName",
        type: "text",
        placeholder: "cabinet_cabinetName",
        label: "cabinet_cabinetName",
        header: "cabinet cabinetName",
        width: 110,
        parentField: "cabinents",
        id: "cabinet_cabinetName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "cabinetName",
        show: true,
        field: "cabinet_cabinetName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Racks",
        label: "Racks",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Racks",
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: true,
        textAlign: "Center",
        show: true,
        field: "racks",
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "racks_rackName",
        type: "text",
        placeholder: "racks_rackName",
        label: "racks_rackName",
        header: "racks rackName",
        width: 110,
        parentField: "racks",
        id: "racks_rackName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "rackName",
        show: true,
        field: "racks_rackName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Product Name",
        derivedValue: "productName=productName=productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "productName",
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Products_productName",
        type: "text",
        placeholder: "Products_productName",
        label: "Products_productName",
        header: "Products productName",
        width: 110,
        parentField: "productName",
        id: "Products_productName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "productName",
        show: true,
        field: "Products_productName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "productQuantityBeingAdded",
        type: "text",
        placeholder: "Product Quantity Being Added",
        label: "Product Quantity Being Added",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Product Quantity Being Added",
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "productQuantityBeingAdded",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        label: "Expiration Date",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Expiration Date",
        derivedValue: "expirationDate=expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "date",
        textAlign: "Center",
        show: true,
        field: "expirationDate",
        showOrHideFields: [],
        fieldName: "expirationDate",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Lot Id",
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "lotId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

 const closeOrderFormModal = () => {
   setopenFormModal(false);
   setTableActionType('');
   setCustomActionType('');
 }

 const getFilteredFormFields = () =>{
  if(customActionType === 'plus') {
    return getActionFormFields().filter( field => !field.excludeInPlus);
  } else {
    return getActionFormFields().filter( field => !field.excludeInMinus);
  }
}

  const getFormModal = () => {
    return <FormModal
    openFormModal={openFormModal}
    tableRowData={selectedRowData}
    closeFormModal={closeOrderFormModal}
    type={actionModalHeaders}
    tablefieldsToShow={getTableFields}
    originalTableFields={getTableFields}
    formType={'add'}
    formFields={() => tableActionType === 'Place Order' ? getOrderFields() : customActionType === 'Move Rack' ? getMoveToRackFormFields() : getFilteredFormFields()}
    filterCriteria={{}}
    getDataFromServer={getDataFromServerRef.current}
    editRequired={true}
    apiUrl={actionApiUrls}
    routeTo={'inventory'}
    displayViewOfForm={'modal'}
    entityType={'inventory'}
    selectedId={selectedRowData._id}
    setValuesWhileAdd={true}
    addDefaultFields={ customActionType === 'minus' ? { type : 'sub'} : null}
    formHeader={actionModalHeaders}
    watchList={customActionType === 'minus' ?['facility','productName','lotId','cabinents','racks'] : null}
  />
  }

  function customType (rowData,column,getDataFromServer){

    if(column.field === 'addToOrderList'){
      return <Button
      className="add_to_orders_btn btn-btn-outline"
      label={
        <>
          <span style={{ fontSize: 12, fontWeight: 'bold' }}>
            Place Order
          </span>
        </>
    }
    severity={"primary"}
    onClick={() => {
      if(rowData?.needToOrder) {
        rowData.quantity = rowData.needToOrder;
      } else {
        rowData.quantity = 0;
      }
      setopenFormModal(true);
      setActionApiUrls('orders')
      setActionModalHeaders('To Order')
      getDataFromServerRef.current =  getDataFromServer
      setSelectedRowData(rowData);
      setTableActionType('Place Order')
    }}
    outlined
    />
    } else if(column.field === 'addConsumeActions') {
      return (
        <div className='row'>
          <div style={{display : 'flex', justifyContent:'space-around' }}>
          <div style={{ backgroundColor : 'red'}} className="custom-acton-button">
          <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='minus'
                title='Remove Quantity from Inventory'
                data-toggle="tool-tip"
                style={{ color: '#fff', width: '20', cursor: 'pointer' }}
                onClick={() =>  {
                  setCustomActionType('minus');
                  getDataFromServerRef.current =  getDataFromServer
                  setActionApiUrls(apiCalls.inventries);
                  setActionModalHeaders('Remove Quantity From Inventory');
                  setSelectedRowData(rowData);
                  setopenFormModal(true);
                  }
                } />
          </div>
           <div style={{backgroundColor : 'green'}} className="custom-acton-button">
           <FontAwesomeIcon
              className='genderIconAlignment'
              color='#fff'
              icon='plus'
              data-toggle="tool-tip"
              title='Add Quantity to Inventory'
              style={{ color: '#fff', cursor: 'pointer', width : '20' }}
              onClick={() => {
                setCustomActionType('plus');
                getDataFromServerRef.current =  getDataFromServer
                setActionApiUrls(apiCalls.inventries);
                setActionModalHeaders('Add Quantity to Inventory');
                setSelectedRowData(rowData);
                setopenFormModal(true);
              }
              } />
           </div>
           <div style={{backgroundColor : 'blue'}} className="custom-acton-button">
           <FontAwesomeIcon
              className='genderIconAlignment'
              color='#fff'
              icon='arrow-right'
              data-toggle="tool-tip"
              title='Move The Rack'
              style={{ color: '#fff', cursor: 'pointer', width : '20' }}
              onClick={() => {
                setActionApiUrls('inventories/moveCabinetsAndRacks')
                setActionModalHeaders('Move To Another Rack')
                getDataFromServerRef.current =  getDataFromServer
                setSelectedRowData(rowData);
                setCustomActionType('Move Rack')
                setopenFormModal(true);
              }
              } />
           </div>
          </div>
        </div >
      )
    }

  }

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.inventries}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {openFormModal && selectedRowData && getDataFromServerRef.current && getFormModal()}
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.inventries}
          globalSearch={
            "LotId/Facility/Product Name"
          }
          displayName="Inventory"
          type="Inventries"
          routeTo={apiCalls.inventries}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.inventries.toLowerCase()}
          apiUrl={apiCalls.inventries}
          selectedId={params.id}
          formHeader={'Add Product To Inventory'}
          sidebarRender={true}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="inventries"
          apiUrl={apiCalls.inventries}
        />
      ) : null}
    </span>
  );
};

export default Inventries;
