import React, { useEffect, useState } from 'react';
import {Scrollbar} from 'smooth-scrollbar-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SidebarContent from './SidebarContent';
import { SidebarProps } from '../../../shared/prop-types/ReducerProps';

import fetch from "../../../config/service";
import apiCalls from '../../../config/apiCalls';

const Sidebar = (props) => {
  
  const [menuList, setMenuList] = useState([]);
  const [loginCredentials, setLoginCredentials] = useState({});

  

  useEffect(() => {
    let loginCredentials = localStorage.getItem("loginCredentials");
    if (loginCredentials) {
      loginCredentials = JSON.parse(loginCredentials);
      setLoginCredentials(loginCredentials);
    }
    getMenuListFromServer()  
    return () => {
      
    };
  }, []);

  const getMenuListFromServer1 = async () => {
    //   let loginCredentials = this.state.loginCredentials;
    //   if (loginCredentials) {
    //     let filterCriteria = {}, url;
    //     filterCriteria.sortfield = 'sequenceNo';
    //     filterCriteria.direction = 'asc';
    //     // url = `menus?filter=${JSON.stringify(filterCriteria)}`;
    //     // return fetch('GET', url)
    //     //   .then(async (res) => {
    //     //     if (res && res.menus && res.menus.length > 0) {
   let menuList = [{
  "icon": "employee",
  "displayTitle": "Employees",
  "title": "Employee",
  "route": "/employee"
},{
  "icon": "activities",
  "displayTitle": "Activitiess",
  "title": "Activities",
  "route": "/activities"
},{
  "icon": "email templates",
  "displayTitle": "Email Templatess",
  "title": "Email Templates",
  "route": "/email templates"
},{
  "icon": "roles",
  "displayTitle": "Roless",
  "title": "Roles",
  "route": "/roles"
},{
  "icon": "upload history",
  "displayTitle": "Upload Historys",
  "title": "Upload History",
  "route": "/upload history"
},{
  "icon": "email status",
  "displayTitle": "Email Statuss",
  "title": "Email Status",
  "route": "/email status"
},{
  "icon": "facilities",
  "displayTitle": "Facilitiess",
  "title": "Facilities",
  "route": "/facilities"
},{
  "icon": "cabinet",
  "displayTitle": "Cabinets",
  "title": "Cabinet",
  "route": "/cabinet"
},{
  "icon": "min inventory by state",
  "displayTitle": "Min Inventory By States",
  "title": "Min Inventory By State",
  "route": "/min inventory by state"
},{
  "icon": "categories",
  "displayTitle": "Categoriess",
  "title": "Categories",
  "route": "/categories"
},{
  "icon": "products",
  "displayTitle": "Productss",
  "title": "Products",
  "route": "/products"
},{
  "icon": "storage unit items",
  "displayTitle": "Storage Unit Itemss",
  "title": "Storage Unit Items",
  "route": "/storage unit items"
},{
  "icon": "product quantities",
  "displayTitle": "Product Quantitiess",
  "title": "Product Quantities",
  "route": "/product quantities"
},{
  "icon": "expiration items",
  "displayTitle": "Expiration Itemss",
  "title": "Expiration Items",
  "route": "/expiration items"
},{
  "icon": "expired items",
  "displayTitle": "Expired Itemss",
  "title": "Expired Items",
  "route": "/expired items"
},{
  "icon": "missing items count",
  "displayTitle": "Missing Items Counts",
  "title": "Missing Items Count",
  "route": "/missing items count"
},{
  "icon": "missingitems",
  "displayTitle": "MissingItemss",
  "title": "MissingItems",
  "route": "/missingitems"
},{
  "icon": "recall items",
  "displayTitle": "Recall Itemss",
  "title": "Recall Items",
  "route": "/recall items"
},{
  "icon": "items recalled",
  "displayTitle": "Items Recalleds",
  "title": "Items Recalled",
  "route": "/items recalled"
},{
  "icon": "recalls",
  "displayTitle": "Recallss",
  "title": "Recalls",
  "route": "/recalls"
},{
  "icon": "facility staffs",
  "displayTitle": "Facility Staffss",
  "title": "Facility Staffs",
  "route": "/facility staffs"
},{
  "icon": "orders",
  "displayTitle": "Orderss",
  "title": "Orders",
  "route": "/orders"
},{
  "icon": "placed orders",
  "displayTitle": "Placed Orderss",
  "title": "Placed Orders",
  "route": "/placed orders"
},{
  "icon": "fulfilled orders",
  "displayTitle": "Fulfilled Orderss",
  "title": "Fulfilled Orders",
  "route": "/fulfilled orders"
},{
  "icon": "required inventory",
  "displayTitle": "Required Inventorys",
  "title": "Required Inventory",
  "route": "/required inventory"
},{
  "icon": "min inventory by products",
  "displayTitle": "Min Inventory By Productss",
  "title": "Min Inventory By Products",
  "route": "/min inventory by products"
},{
  "icon": "distributors",
  "displayTitle": "Distributorss",
  "title": "Distributors",
  "route": "/distributors"
},{
  "icon": "helps",
  "displayTitle": "Helpss",
  "title": "Helps",
  "route": "/helps"
},{
  "icon": "company users",
  "displayTitle": "Company Userss",
  "title": "Company Users",
  "route": "/company users"
},{
  "icon": "alerts",
  "displayTitle": "Alertss",
  "title": "Alerts",
  "route": "/alerts"
},{
  "icon": "notifications",
  "displayTitle": "Notificationss",
  "title": "Notifications",
  "route": "/notifications"
},{
  "icon": "payments",
  "displayTitle": "Paymentss",
  "title": "Payments",
  "route": "/payments"
},{
  "icon": "invoices",
  "displayTitle": "Invoicess",
  "title": "Invoices",
  "route": "/invoices"
},{
  "icon": "tickets",
  "displayTitle": "Ticketss",
  "title": "Tickets",
  "route": "/tickets"
},{
  "icon": "ticketsubjects",
  "displayTitle": "TicketSubjectss",
  "title": "TicketSubjects",
  "route": "/ticketsubjects"
},{
  "icon": "cartlists",
  "displayTitle": "CartListss",
  "title": "CartLists",
  "route": "/cartlists"
},{
  "icon": "racks",
  "displayTitle": "Rackss",
  "title": "Racks",
  "route": "/racks"
},{
  "icon": "inventry",
  "displayTitle": "Inventrys",
  "title": "Inventry",
  "route": "/inventry"
},{
  "icon": "mantainance",
  "displayTitle": "Mantainances",
  "title": "Mantainance",
  "route": "/mantainance"
},{
  "icon": "subcategory",
  "displayTitle": "Subcategorys",
  "title": "Subcategory",
  "route": "/subcategory"
},{
  "icon": "change settings",
  "displayTitle": "Change settingss",
  "title": "Change settings",
  "route": "/change settings"
},{
  "icon": "category type",
  "displayTitle": "Category Types",
  "title": "Category Type",
  "route": "/category type"
}];
    //     // menuList = res.menus;
    //     await this.setState({
    //       menuList: menuList
    //     })
    //     //     }
    //     //   })
    //   }
  }

  const camelizedString = (str) => {
    str = str.charAt(0).toLowerCase() + str.slice(1);
    return str.replace(/\W+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }


  const getMenuListFromServer = async () => {    
    if (loginCredentials) {
      let filterCriteria = {}, url;
      filterCriteria.sortfield = 'sequenceNo';
      filterCriteria.direction = 'asc';
      url = `menus?filter=${JSON.stringify(filterCriteria)}`;
      console.log("urllllll", url)
      return fetch('GET', url)
        .then(async (res) => {
          console.log(res)
          if (res && res.menulists && res.menulists.length > 0) {
            let menuList = res.menulists;
            let rolePermissions = localStorage.getItem("rolePermissions");
            rolePermissions = JSON.parse(rolePermissions);
            if (rolePermissions) {
              let neWmenuList = [];
              let keys = Object.keys(rolePermissions);
              if (menuList) {
                menuList.forEach((item) => {
                  if (item.submenus && item.submenus.length > 0) {
                    let newSubmenus = [];
                    item.submenus.map(sitem => {
                      keys.forEach(async(element) => {
                        if (camelizedString(sitem.displayTitle) === camelizedString(element)) {
                          if (rolePermissions[element].type && rolePermissions[element].type !== "NoView") {
                            newSubmenus.push(sitem);
                          }
                        }
                      });
                    })
                    if (newSubmenus && newSubmenus.length > 0) {
                      item.submenus = newSubmenus;
                      neWmenuList.push(item);
                    }
                  } else {
                    keys.forEach(async (element,index) => {
                      if (camelizedString(item.displayTitle) === camelizedString(element)) {
                        if (rolePermissions[element].type && rolePermissions[element].type !== "NoView") {
                          neWmenuList.push(item);
                        }
                      }
                    });
                  }
                });
              }
              setMenuList(neWmenuList);
            }
          }
        })

    }
  }

  
    const { changeToDark, changeToLight, changeMobileSidebarVisibility, sidebar } = props;
    const sidebarClass = classNames({
      sidebar: true,
      'sidebar--show': sidebar.show,
      'sidebar--collapse': sidebar.collapse,
    });

    return (
      <div className={sidebarClass}>
        <button className="sidebar__back" type="button" onClick={changeMobileSidebarVisibility} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className="sidebar__wrapper sidebar__wrapper--desktop">
            <SidebarContent
              onClick={() => { }}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={menuList}
            />
          </div>
          <div className="sidebar__wrapper sidebar__wrapper--mobile">
            <SidebarContent
              onClick={changeMobileSidebarVisibility}
              changeToDark={changeToDark}
              changeToLight={changeToLight}
              menuList={menuList}
            />
          </div>
        </Scrollbar>
      </div>
    );
  };


Sidebar.propTypes = {
  sidebar: SidebarProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
};

export default Sidebar;