import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ConsumedProducts from './components/ConsumedProducts';

const consumedProducts = (props,{ t }) => (
  <Container>
    <ConsumedProducts {...props} />
  </Container>
);

consumedProducts.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(consumedProducts);
