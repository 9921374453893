import React, { useEffect, useRef, useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import fetch from '../../../config/service';
const DoMultiSelectApi = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const optionLabel = props.optionLabel;
    const name = props.name;
    const placeholder = props.placeholder;
    const maxSelectedLabels = props.maxSelectedLabels;
    const searchApi = props.searchApi;
    const searchField = props.searchField;
    const [options, setOptions] = useState([]);
    let selectedOptions = useRef([]);
    let field = props.field;

    let parentField = props.watch && props.item.queryParentDependent ? props.watch(props.item.queryParentDependent) : '';
    useEffect(() => {
        getOptions();
      }, [parentField]);

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };


    function getOptions() {
        let url = '';
        let query = '';
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };
        if(props.item.filterById){
            filterCriteria['criteria'].push({
                "key":props.item.filterIdName,
                "value":props.rowData._id,
                "type":"eq"
            })
        }
        if(props.item.hasDependencyField && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]){
            filterCriteria['criteria'].push({
                "key":props.item.fieldForKey,
                "value":props.getValues(props.item.hasDependencyField)["_id"],
                "type":"eq"
            })
        }
        if(props?.item?.customQuery && props?.item?.customQuery?.length > 0){
            props.item.customQuery?.map( field => {
                query +=field + '='+ (props.getValues(field)?.['_id'] ? props.getValues(field)?.['_id'] : props.getValues(field)) + '&'
            })
        }
        let apiUrl = props.searchApi?.toLowerCase();
        if(query){
            url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}&${query}`;
        } else {
            url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        }
        return fetch('GET', url)
            .then((response) => {
                if(props.item?.customResponseKey){
                    apiUrl = props.item?.customResponseKey;
                }
                if (response) {
                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                        if(props.item?.responseType === 'string') {
                            response[apiUrl] = response[apiUrl].map(item => {
                                return {[props.item.optionLabel] : item , _id : item};
                            })
                        }
                        setOptions(response[apiUrl]);
                    }
                }
            }).catch((err) => {
                return err;
            });
    }
    const optionsSelected = (e) => {
        selectedOptions.current = options.filter(option => e.value.includes(option._id));
        props.setMultiSelectApiSelectedOption(selectedOptions.current)
    }
    return (
        <div className="flex flex-column">
             <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>  
            <MultiSelect
                id={field.name}
                name={name}
                value={field.value}
                options={options}
                onChange={(e) => {field.onChange(e.value); optionsSelected(e)}}
                optionLabel={optionLabel}
                optionValue='_id'
                placeholder={placeholder}
                filter={true}
                maxSelectedLabels={maxSelectedLabels}
            />
            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )

}

export default DoMultiSelectApi;