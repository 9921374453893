import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FacilityStaffs from './components/FacilityStaffs';

const facilityStaffs = (props,{ t }) => (
  <Container>
    <FacilityStaffs {...props} />
  </Container>
);

facilityStaffs.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(facilityStaffs);
