/* eslint indent: "off" */
import React from 'react';
import { Route, Routes, createBrowserRouter,Outlet } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';
import Register from '../Register/index';

// Login
import Landing from '../Landing/LogIn';
// import LoginCheck from '../Landing/loginCheck';

// Dashboard
import Home from '../Dashboard/index';

// Profile
import ChangePassword from '../Account/ChangePassword/index';
import ChangeRecoverPassword from '../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../Account/ForgotPassword/index';
import LoginChangePassword from '../Account/LoginChangePassword/index';
import Profile from '../Account/Profile';

import EmailTemplate from '../Cruds/EmailTemplates';
import Roles from '../Cruds/Roles';
import Activities from '../Cruds/Activities'
import EmailStatus from '../Cruds/EmailStatus/index';
// Tables
import Employees from '../Cruds/Employees/index';
import Facilities from '../Cruds/Facilities/index';
import Cabinets from '../Cruds/Cabinets/index';
import MinInventoryByStates from '../Cruds/MinInventoryByStates/index';
import Categories from '../Cruds/Categories/index';
import Products from '../Cruds/Products/index';
import StorageUnitItems from '../Cruds/StorageUnitItems/index';
import ProductQuantities from '../Cruds/ProductQuantities/index';
import ExpirationItems from '../Cruds/ExpirationItems/index';
import ExpiredItems from '../Cruds/ExpiredItems/index';
import MissingItemsCounts from '../Cruds/MissingItemsCounts/index';
import MissingItems from '../Cruds/MissingItems/index';
import RecallItems from '../Cruds/RecallItems/index';
import ItemsRecalleds from '../Cruds/ItemsRecalleds/index';
import Recalls from '../Cruds/Recalls/index';
import FacilityStaffs from '../Cruds/FacilityStaffs/index';
import Orders from '../Cruds/Orders/index';
import PlacedOrders from '../Cruds/PlacedOrders/index';
import FulfilledOrders from '../Cruds/FulfilledOrders/index';
import RequiredInventories from '../Cruds/RequiredInventories/index';
import MinInventoryByProducts from '../Cruds/MinInventoryByProducts/index';
import Distributors from '../Cruds/Distributors/index';
import Helps from '../Cruds/Helps/index';
import CompanyUsers from '../Cruds/CompanyUsers/index';
import Alerts from '../Cruds/Alerts/index';
import Notifications from '../Cruds/Notifications/index';
import Payments from '../Cruds/Payments/index';
import Invoices from '../Cruds/Invoices/index';
import Tickets from '../Cruds/Tickets/index';
import TicketSubjects from '../Cruds/TicketSubjects/index';
import CartLists from '../Cruds/CartLists/index';
import Racks from '../Cruds/Racks/index';
import Inventries from '../Cruds/Inventries/index';
import Mantainances from '../Cruds/Mantainances/index';
import Subcategories from '../Cruds/Subcategories/index';
import CategoryTypes from '../Cruds/CategoryTypes/index';
import InventoryByProducts from '../Cruds/InventoryByProducts/index';
import InventoryByRacks from '../Cruds/InventoryByRacks/index';
import InventoryByLots from '../Cruds/InventoryByLots/index';
import Assets from '../Cruds/Assets/index';
import AssetMaintenances from '../Cruds/AssetMaintenances/index';
import ConsumedProducts from '../Cruds/ConsumedProducts/index';


// import Employees from '../Cruds/Employees/index';

import Settings from '../Cruds/Settings/index'
import AdminSettings from '../Settings/index'
import Uploads from '../Cruds/Uploads/index';
// 404 page not found 
import ErrorNotFound from '../Account/404/index';
import config from '../../config/config';
import SampleForm from '../../Sample/SampleForm.jsx';

const Tables = () => {
  let roles = localStorage.rolePermissions
    ? JSON.parse(localStorage.rolePermissions)
    : false;
  if (roles) {
    return (
      <>
    <Routes>
      <Route path='/sampleform'
        element={<SampleForm />}
      ></Route>
<Route exact
       path="/employees"
      element={
              roles['Employees'] &&
                (roles['Employees'].type === "Edit" || roles['Employees'].type === "View")
                ? <Employees/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_employees"
          element={
            roles['Employees'] &&
              (roles['Employees'].type === "Edit" )
              ? <Employees/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_employees/:id"
          element={
            roles['Employees'] &&
              (roles['Employees'].type === "Edit" )
              ? <Employees/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_employees/:id"
          element={
            roles['Employees'] &&
              (roles['Employees'].type === "Edit" || roles['Employees'].type === "View")
              ? <Employees  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/facilities"
      element={
              roles['Facilities'] &&
                (roles['Facilities'].type === "Edit" || roles['Facilities'].type === "View")
                ? <Facilities/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_facilities"
          element={
            roles['Facilities'] &&
              (roles['Facilities'].type === "Edit" )
              ? <Facilities/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_facilities/:id"
          element={
            roles['Facilities'] &&
              (roles['Facilities'].type === "Edit" )
              ? <Facilities/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_facilities/:id"
          element={
            roles['Facilities'] &&
              (roles['Facilities'].type === "Edit" || roles['Facilities'].type === "View")
              ? <Facilities  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/cabinets"
      element={
              roles['Cabinets'] &&
                (roles['Cabinets'].type === "Edit" || roles['Cabinets'].type === "View")
                ? <Cabinets/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_cabinets"
          element={
            roles['Cabinets'] &&
              (roles['Cabinets'].type === "Edit" )
              ? <Cabinets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_cabinets/:id"
          element={
            roles['Cabinets'] &&
              (roles['Cabinets'].type === "Edit" )
              ? <Cabinets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_cabinets/:id"
          element={
            roles['Cabinets'] &&
              (roles['Cabinets'].type === "Edit" || roles['Cabinets'].type === "View")
              ? <Cabinets  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/minInventoryByStates"
      element={
              roles['Min Inventory By States'] &&
                (roles['Min Inventory By States'].type === "Edit" || roles['Min Inventory By States'].type === "View")
                ? <MinInventoryByStates/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_minInventoryByStates"
          element={
            roles['Min Inventory By States'] &&
              (roles['Min Inventory By States'].type === "Edit" )
              ? <MinInventoryByStates/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_minInventoryByStates/:id"
          element={
            roles['Min Inventory By States'] &&
              (roles['Min Inventory By States'].type === "Edit" )
              ? <MinInventoryByStates/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_minInventoryByStates/:id"
          element={
            roles['Min Inventory By States'] &&
              (roles['Min Inventory By States'].type === "Edit" || roles['Min Inventory By States'].type === "View")
              ? <MinInventoryByStates  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/categories"
      element={
              roles['Categories'] &&
                (roles['Categories'].type === "Edit" || roles['Categories'].type === "View")
                ? <Categories/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_categories"
          element={
            roles['Categories'] &&
              (roles['Categories'].type === "Edit" )
              ? <Categories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_categories/:id"
          element={
            roles['Categories'] &&
              (roles['Categories'].type === "Edit" )
              ? <Categories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_categories/:id"
          element={
            roles['Categories'] &&
              (roles['Categories'].type === "Edit" || roles['Categories'].type === "View")
              ? <Categories  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/products"
      element={
              roles['Products'] &&
                (roles['Products'].type === "Edit" || roles['Products'].type === "View")
                ? <Products/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_products"
          element={
            roles['Products'] &&
              (roles['Products'].type === "Edit" )
              ? <Products/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_products/:id"
          element={
            roles['Products'] &&
              (roles['Products'].type === "Edit" )
              ? <Products/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_products/:id"
          element={
            roles['Products'] &&
              (roles['Products'].type === "Edit" || roles['Products'].type === "View")
              ? <Products  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/storageUnitItems"
      element={
              roles['Storage Unit Items'] &&
                (roles['Storage Unit Items'].type === "Edit" || roles['Storage Unit Items'].type === "View")
                ? <StorageUnitItems/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_storageUnitItems"
          element={
            roles['Storage Unit Items'] &&
              (roles['Storage Unit Items'].type === "Edit" )
              ? <StorageUnitItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_storageUnitItems/:id"
          element={
            roles['Storage Unit Items'] &&
              (roles['Storage Unit Items'].type === "Edit" )
              ? <StorageUnitItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_storageUnitItems/:id"
          element={
            roles['Storage Unit Items'] &&
              (roles['Storage Unit Items'].type === "Edit" || roles['Storage Unit Items'].type === "View")
              ? <StorageUnitItems  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/productQuantities"
      element={
              roles['Product Quantities'] &&
                (roles['Product Quantities'].type === "Edit" || roles['Product Quantities'].type === "View")
                ? <ProductQuantities/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_productQuantities"
          element={
            roles['Product Quantities'] &&
              (roles['Product Quantities'].type === "Edit" )
              ? <ProductQuantities/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_productQuantities/:id"
          element={
            roles['Product Quantities'] &&
              (roles['Product Quantities'].type === "Edit" )
              ? <ProductQuantities/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_productQuantities/:id"
          element={
            roles['Product Quantities'] &&
              (roles['Product Quantities'].type === "Edit" || roles['Product Quantities'].type === "View")
              ? <ProductQuantities  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/expirationItems"
      element={
              roles['Expiration Items'] &&
                (roles['Expiration Items'].type === "Edit" || roles['Expiration Items'].type === "View")
                ? <ExpirationItems/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_expirationItems"
          element={
            roles['Expiration Items'] &&
              (roles['Expiration Items'].type === "Edit" )
              ? <ExpirationItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_expirationItems/:id"
          element={
            roles['Expiration Items'] &&
              (roles['Expiration Items'].type === "Edit" )
              ? <ExpirationItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_expirationItems/:id"
          element={
            roles['Expiration Items'] &&
              (roles['Expiration Items'].type === "Edit" || roles['Expiration Items'].type === "View")
              ? <ExpirationItems  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/expiredItems"
      element={
              roles['Expired Items'] &&
                (roles['Expired Items'].type === "Edit" || roles['Expired Items'].type === "View")
                ? <ExpiredItems/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_expiredItems"
          element={
            roles['Expired Items'] &&
              (roles['Expired Items'].type === "Edit" )
              ? <ExpiredItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_expiredItems/:id"
          element={
            roles['Expired Items'] &&
              (roles['Expired Items'].type === "Edit" )
              ? <ExpiredItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_expiredItems/:id"
          element={
            roles['Expired Items'] &&
              (roles['Expired Items'].type === "Edit" || roles['Expired Items'].type === "View")
              ? <ExpiredItems  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/missingItemsCounts"
      element={
              roles['Missing Items Counts'] &&
                (roles['Missing Items Counts'].type === "Edit" || roles['Missing Items Counts'].type === "View")
                ? <MissingItemsCounts/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_missingItemsCounts"
          element={
            roles['Missing Items Counts'] &&
              (roles['Missing Items Counts'].type === "Edit" )
              ? <MissingItemsCounts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_missingItemsCounts/:id"
          element={
            roles['Missing Items Counts'] &&
              (roles['Missing Items Counts'].type === "Edit" )
              ? <MissingItemsCounts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_missingItemsCounts/:id"
          element={
            roles['Missing Items Counts'] &&
              (roles['Missing Items Counts'].type === "Edit" || roles['Missing Items Counts'].type === "View")
              ? <MissingItemsCounts  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/missingItems"
      element={
              roles['Missing Items'] &&
                (roles['Missing Items'].type === "Edit" || roles['Missing Items'].type === "View")
                ? <MissingItems/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_missingItems"
          element={
            roles['Missing Items'] &&
              (roles['Missing Items'].type === "Edit" )
              ? <MissingItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_missingItems/:id"
          element={
            roles['Missing Items'] &&
              (roles['Missing Items'].type === "Edit" )
              ? <MissingItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_missingItems/:id"
          element={
            roles['Missing Items'] &&
              (roles['Missing Items'].type === "Edit" || roles['Missing Items'].type === "View")
              ? <MissingItems  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/recallItems"
      element={
              roles['Recall Items'] &&
                (roles['Recall Items'].type === "Edit" || roles['Recall Items'].type === "View")
                ? <RecallItems/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_recallItems"
          element={
            roles['Recall Items'] &&
              (roles['Recall Items'].type === "Edit" )
              ? <RecallItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_recallItems/:id"
          element={
            roles['Recall Items'] &&
              (roles['Recall Items'].type === "Edit" )
              ? <RecallItems/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_recallItems/:id"
          element={
            roles['Recall Items'] &&
              (roles['Recall Items'].type === "Edit" || roles['Recall Items'].type === "View")
              ? <RecallItems  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/itemsRecalleds"
      element={
              roles['Items Recalleds'] &&
                (roles['Items Recalleds'].type === "Edit" || roles['Items Recalleds'].type === "View")
                ? <ItemsRecalleds/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_itemsRecalleds"
          element={
            roles['Items Recalleds'] &&
              (roles['Items Recalleds'].type === "Edit" )
              ? <ItemsRecalleds/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_itemsRecalleds/:id"
          element={
            roles['Items Recalleds'] &&
              (roles['Items Recalleds'].type === "Edit" )
              ? <ItemsRecalleds/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_itemsRecalleds/:id"
          element={
            roles['Items Recalleds'] &&
              (roles['Items Recalleds'].type === "Edit" || roles['Items Recalleds'].type === "View")
              ? <ItemsRecalleds  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/recalls"
      element={
              roles['Recalls'] &&
                (roles['Recalls'].type === "Edit" || roles['Recalls'].type === "View")
                ? <Recalls/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_recalls"
          element={
            roles['Recalls'] &&
              (roles['Recalls'].type === "Edit" )
              ? <Recalls/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_recalls/:id"
          element={
            roles['Recalls'] &&
              (roles['Recalls'].type === "Edit" )
              ? <Recalls/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_recalls/:id"
          element={
            roles['Recalls'] &&
              (roles['Recalls'].type === "Edit" || roles['Recalls'].type === "View")
              ? <Recalls  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/facilityStaffs"
      element={
              roles['Facility Staffs'] &&
                (roles['Facility Staffs'].type === "Edit" || roles['Facility Staffs'].type === "View")
                ? <FacilityStaffs/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_facilityStaffs"
          element={
            roles['Facility Staffs'] &&
              (roles['Facility Staffs'].type === "Edit" )
              ? <FacilityStaffs/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_facilityStaffs/:id"
          element={
            roles['Facility Staffs'] &&
              (roles['Facility Staffs'].type === "Edit" )
              ? <FacilityStaffs/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_facilityStaffs/:id"
          element={
            roles['Facility Staffs'] &&
              (roles['Facility Staffs'].type === "Edit" || roles['Facility Staffs'].type === "View")
              ? <FacilityStaffs  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/orders"
      element={
              roles['Orders'] &&
                (roles['Orders'].type === "Edit" || roles['Orders'].type === "View")
                ? <Orders/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_orders"
          element={
            roles['Orders'] &&
              (roles['Orders'].type === "Edit" )
              ? <Orders/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_orders/:id"
          element={
            roles['Orders'] &&
              (roles['Orders'].type === "Edit" )
              ? <Orders/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_orders/:id"
          element={
            roles['Orders'] &&
              (roles['Orders'].type === "Edit" || roles['Orders'].type === "View")
              ? <Orders  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/placedOrders"
      element={
              roles['Placed Orders'] &&
                (roles['Placed Orders'].type === "Edit" || roles['Placed Orders'].type === "View")
                ? <PlacedOrders/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_placedOrders"
          element={
            roles['Placed Orders'] &&
              (roles['Placed Orders'].type === "Edit" )
              ? <PlacedOrders/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_placedOrders/:id"
          element={
            roles['Placed Orders'] &&
              (roles['Placed Orders'].type === "Edit" )
              ? <PlacedOrders/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_placedOrders/:id"
          element={
            roles['Placed Orders'] &&
              (roles['Placed Orders'].type === "Edit" || roles['Placed Orders'].type === "View")
              ? <PlacedOrders  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/fulfilledOrders"
      element={
              roles['Fulfilled Orders'] &&
                (roles['Fulfilled Orders'].type === "Edit" || roles['Fulfilled Orders'].type === "View")
                ? <FulfilledOrders/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_fulfilledOrders"
          element={
            roles['Fulfilled Orders'] &&
              (roles['Fulfilled Orders'].type === "Edit" )
              ? <FulfilledOrders/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_fulfilledOrders/:id"
          element={
            roles['Fulfilled Orders'] &&
              (roles['Fulfilled Orders'].type === "Edit" )
              ? <FulfilledOrders/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_fulfilledOrders/:id"
          element={
            roles['Fulfilled Orders'] &&
              (roles['Fulfilled Orders'].type === "Edit" || roles['Fulfilled Orders'].type === "View")
              ? <FulfilledOrders  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/requiredInventory"
      element={
              roles['Required Inventory'] &&
                (roles['Required Inventory'].type === "Edit" || roles['Required Inventory'].type === "View")
                ? <RequiredInventories/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_requiredInventory"
          element={
            roles['Required Inventory'] &&
              (roles['Required Inventory'].type === "Edit" )
              ? <RequiredInventories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_requiredInventory/:id"
          element={
            roles['Required Inventory'] &&
              (roles['Required Inventory'].type === "Edit" )
              ? <RequiredInventories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_requiredInventory/:id"
          element={
            roles['Required Inventory'] &&
              (roles['Required Inventory'].type === "Edit" || roles['Required Inventory'].type === "View")
              ? <RequiredInventories  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/minInventoryByProducts"
      element={
              roles['Min Inventory By Products'] &&
                (roles['Min Inventory By Products'].type === "Edit" || roles['Min Inventory By Products'].type === "View")
                ? <MinInventoryByProducts/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_minInventoryByProducts"
          element={
            roles['Min Inventory By Products'] &&
              (roles['Min Inventory By Products'].type === "Edit" )
              ? <MinInventoryByProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_minInventoryByProducts/:id"
          element={
            roles['Min Inventory By Products'] &&
              (roles['Min Inventory By Products'].type === "Edit" )
              ? <MinInventoryByProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_minInventoryByProducts/:id"
          element={
            roles['Min Inventory By Products'] &&
              (roles['Min Inventory By Products'].type === "Edit" || roles['Min Inventory By Products'].type === "View")
              ? <MinInventoryByProducts  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/distributors"
      element={
              roles['Distributors'] &&
                (roles['Distributors'].type === "Edit" || roles['Distributors'].type === "View")
                ? <Distributors/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_distributors"
          element={
            roles['Distributors'] &&
              (roles['Distributors'].type === "Edit" )
              ? <Distributors/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_distributors/:id"
          element={
            roles['Distributors'] &&
              (roles['Distributors'].type === "Edit" )
              ? <Distributors/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_distributors/:id"
          element={
            roles['Distributors'] &&
              (roles['Distributors'].type === "Edit" || roles['Distributors'].type === "View")
              ? <Distributors  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/helps"
      element={
              roles['Helps'] &&
                (roles['Helps'].type === "Edit" || roles['Helps'].type === "View")
                ? <Helps/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_helps"
          element={
            roles['Helps'] &&
              (roles['Helps'].type === "Edit" )
              ? <Helps/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_helps/:id"
          element={
            roles['Helps'] &&
              (roles['Helps'].type === "Edit" )
              ? <Helps/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_helps/:id"
          element={
            roles['Helps'] &&
              (roles['Helps'].type === "Edit" || roles['Helps'].type === "View")
              ? <Helps  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/companyUsers"
      element={
              roles['Company Users'] &&
                (roles['Company Users'].type === "Edit" || roles['Company Users'].type === "View")
                ? <CompanyUsers/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_companyUsers"
          element={
            roles['Company Users'] &&
              (roles['Company Users'].type === "Edit" )
              ? <CompanyUsers/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_companyUsers/:id"
          element={
            roles['Company Users'] &&
              (roles['Company Users'].type === "Edit" )
              ? <CompanyUsers/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_companyUsers/:id"
          element={
            roles['Company Users'] &&
              (roles['Company Users'].type === "Edit" || roles['Company Users'].type === "View")
              ? <CompanyUsers  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/alerts"
      element={
              roles['Alerts'] &&
                (roles['Alerts'].type === "Edit" || roles['Alerts'].type === "View")
                ? <Alerts/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_alerts"
          element={
            roles['Alerts'] &&
              (roles['Alerts'].type === "Edit" )
              ? <Alerts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_alerts/:id"
          element={
            roles['Alerts'] &&
              (roles['Alerts'].type === "Edit" )
              ? <Alerts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_alerts/:id"
          element={
            roles['Alerts'] &&
              (roles['Alerts'].type === "Edit" || roles['Alerts'].type === "View")
              ? <Alerts  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/notifications"
      element={
              roles['Notifications'] &&
                (roles['Notifications'].type === "Edit" || roles['Notifications'].type === "View")
                ? <Notifications/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_notifications"
          element={
            roles['Notifications'] &&
              (roles['Notifications'].type === "Edit" )
              ? <Notifications/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_notifications/:id"
          element={
            roles['Notifications'] &&
              (roles['Notifications'].type === "Edit" )
              ? <Notifications/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_notifications/:id"
          element={
            roles['Notifications'] &&
              (roles['Notifications'].type === "Edit" || roles['Notifications'].type === "View")
              ? <Notifications  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/payments"
      element={
              roles['Payments'] &&
                (roles['Payments'].type === "Edit" || roles['Payments'].type === "View")
                ? <Payments/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_payments"
          element={
            roles['Payments'] &&
              (roles['Payments'].type === "Edit" )
              ? <Payments/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_payments/:id"
          element={
            roles['Payments'] &&
              (roles['Payments'].type === "Edit" )
              ? <Payments/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_payments/:id"
          element={
            roles['Payments'] &&
              (roles['Payments'].type === "Edit" || roles['Payments'].type === "View")
              ? <Payments  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/invoices"
      element={
              roles['Invoices'] &&
                (roles['Invoices'].type === "Edit" || roles['Invoices'].type === "View")
                ? <Invoices/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_invoices"
          element={
            roles['Invoices'] &&
              (roles['Invoices'].type === "Edit" )
              ? <Invoices/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_invoices/:id"
          element={
            roles['Invoices'] &&
              (roles['Invoices'].type === "Edit" )
              ? <Invoices/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_invoices/:id"
          element={
            roles['Invoices'] &&
              (roles['Invoices'].type === "Edit" || roles['Invoices'].type === "View")
              ? <Invoices  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/tickets"
      element={
              roles['Tickets'] &&
                (roles['Tickets'].type === "Edit" || roles['Tickets'].type === "View")
                ? <Tickets/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_tickets"
          element={
            roles['Tickets'] &&
              (roles['Tickets'].type === "Edit" )
              ? <Tickets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_tickets/:id"
          element={
            roles['Tickets'] &&
              (roles['Tickets'].type === "Edit" )
              ? <Tickets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_tickets/:id"
          element={
            roles['Tickets'] &&
              (roles['Tickets'].type === "Edit" || roles['Tickets'].type === "View")
              ? <Tickets  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/ticketSubjects"
      element={
              roles['TicketSubjects'] &&
                (roles['TicketSubjects'].type === "Edit" || roles['TicketSubjects'].type === "View")
                ? <TicketSubjects/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_ticketSubjects"
          element={
            roles['TicketSubjects'] &&
              (roles['TicketSubjects'].type === "Edit" )
              ? <TicketSubjects/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_ticketSubjects/:id"
          element={
            roles['TicketSubjects'] &&
              (roles['TicketSubjects'].type === "Edit" )
              ? <TicketSubjects/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_ticketSubjects/:id"
          element={
            roles['TicketSubjects'] &&
              (roles['TicketSubjects'].type === "Edit" || roles['TicketSubjects'].type === "View")
              ? <TicketSubjects  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/cartLists"
      element={
              roles['CartLists'] &&
                (roles['CartLists'].type === "Edit" || roles['CartLists'].type === "View")
                ? <CartLists/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_cartLists"
          element={
            roles['CartLists'] &&
              (roles['CartLists'].type === "Edit" )
              ? <CartLists/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_cartLists/:id"
          element={
            roles['CartLists'] &&
              (roles['CartLists'].type === "Edit" )
              ? <CartLists/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_cartLists/:id"
          element={
            roles['CartLists'] &&
              (roles['CartLists'].type === "Edit" || roles['CartLists'].type === "View")
              ? <CartLists  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/racks"
      element={
              roles['Racks'] &&
                (roles['Racks'].type === "Edit" || roles['Racks'].type === "View")
                ? <Racks/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_racks"
          element={
            roles['Racks'] &&
              (roles['Racks'].type === "Edit" )
              ? <Racks/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_racks/:id"
          element={
            roles['Racks'] &&
              (roles['Racks'].type === "Edit" )
              ? <Racks/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_racks/:id"
          element={
            roles['Racks'] &&
              (roles['Racks'].type === "Edit" || roles['Racks'].type === "View")
              ? <Racks  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/inventory"
      element={
              roles['Inventory'] &&
                (roles['Inventory'].type === "Edit" || roles['Inventory'].type === "View")
                ? <Inventries/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_inventories"
          element={
            roles['Inventory'] &&
              (roles['Inventory'].type === "Edit" )
              ? <Inventries/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_inventories/:id"
          element={
            roles['Inventory'] &&
              (roles['Inventory'].type === "Edit" )
              ? <Inventries/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_inventories/:id"
          element={
            roles['Inventory'] &&
              (roles['Inventory'].type === "Edit" || roles['Inventory'].type === "View")
              ? <Inventries  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/mantainances"
      element={
              roles['Mantainances'] &&
                (roles['Mantainances'].type === "Edit" || roles['Mantainances'].type === "View")
                ? <Mantainances/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_mantainances"
          element={
            roles['Mantainances'] &&
              (roles['Mantainances'].type === "Edit" )
              ? <Mantainances/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_mantainances/:id"
          element={
            roles['Mantainances'] &&
              (roles['Mantainances'].type === "Edit" )
              ? <Mantainances/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_mantainances/:id"
          element={
            roles['Mantainances'] &&
              (roles['Mantainances'].type === "Edit" || roles['Mantainances'].type === "View")
              ? <Mantainances  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/subcategories"
      element={
              roles['Subcategories'] &&
                (roles['Subcategories'].type === "Edit" || roles['Subcategories'].type === "View")
                ? <Subcategories/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_subcategories"
          element={
            roles['Subcategories'] &&
              (roles['Subcategories'].type === "Edit" )
              ? <Subcategories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_subcategories/:id"
          element={
            roles['Subcategories'] &&
              (roles['Subcategories'].type === "Edit" )
              ? <Subcategories/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_subcategories/:id"
          element={
            roles['Subcategories'] &&
              (roles['Subcategories'].type === "Edit" || roles['Subcategories'].type === "View")
              ? <Subcategories  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/categoryType"
      element={
              roles['Category Type'] &&
                (roles['Category Type'].type === "Edit" || roles['Category Type'].type === "View")
                ? <CategoryTypes/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_categoryType"
          element={
            roles['Category Type'] &&
              (roles['Category Type'].type === "Edit" )
              ? <CategoryTypes/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_categoryType/:id"
          element={
            roles['Category Type'] &&
              (roles['Category Type'].type === "Edit" )
              ? <CategoryTypes/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_categoryType/:id"
          element={
            roles['Category Type'] &&
              (roles['Category Type'].type === "Edit" || roles['Category Type'].type === "View")
              ? <CategoryTypes  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/inventoryByProducts"
      element={
              roles['Inventory By Products'] &&
                (roles['Inventory By Products'].type === "Edit" || roles['Inventory By Products'].type === "View")
                ? <InventoryByProducts/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_inventoryByProducts"
          element={
            roles['Inventory By Products'] &&
              (roles['Inventory By Products'].type === "Edit" )
              ? <InventoryByProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_inventoryByProducts/:id"
          element={
            roles['Inventory By Products'] &&
              (roles['Inventory By Products'].type === "Edit" )
              ? <InventoryByProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_inventoryByProducts/:id"
          element={
            roles['Inventory By Products'] &&
              (roles['Inventory By Products'].type === "Edit" || roles['Inventory By Products'].type === "View")
              ? <InventoryByProducts  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/inventoryByRacks"
      element={
              roles['Inventory By Racks'] &&
                (roles['Inventory By Racks'].type === "Edit" || roles['Inventory By Racks'].type === "View")
                ? <InventoryByRacks/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_inventoryByRacks"
          element={
            roles['Inventory By Racks'] &&
              (roles['Inventory By Racks'].type === "Edit" )
              ? <InventoryByRacks/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_inventoryByRacks/:id"
          element={
            roles['Inventory By Racks'] &&
              (roles['Inventory By Racks'].type === "Edit" )
              ? <InventoryByRacks/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_inventoryByRacks/:id"
          element={
            roles['Inventory By Racks'] &&
              (roles['Inventory By Racks'].type === "Edit" || roles['Inventory By Racks'].type === "View")
              ? <InventoryByRacks  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/inventoryByLots"
      element={
              roles['Inventory By Lots'] &&
                (roles['Inventory By Lots'].type === "Edit" || roles['Inventory By Lots'].type === "View")
                ? <InventoryByLots/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_inventoryByLots"
          element={
            roles['Inventory By Lots'] &&
              (roles['Inventory By Lots'].type === "Edit" )
              ? <InventoryByLots/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_inventoryByLots/:id"
          element={
            roles['Inventory By Lots'] &&
              (roles['Inventory By Lots'].type === "Edit" )
              ? <InventoryByLots/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_inventoryByLots/:id"
          element={
            roles['Inventory By Lots'] &&
              (roles['Inventory By Lots'].type === "Edit" || roles['Inventory By Lots'].type === "View")
              ? <InventoryByLots  />  
              : <ErrorNotFound/>
          }
        />
<Route exact
       path="/mostSoldProducts"
      element={
              roles['Most Sold Products'] &&
                (roles['Most Sold Products'].type === "Edit" || roles['Most Sold Products'].type === "View")
                ? <MostSoldProducts/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_mostSoldProducts"
          element={
            roles['Most Sold Products'] &&
              (roles['Most Sold Products'].type === "Edit" )
              ? <MostSoldProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_mostSoldProducts/:id"
          element={
            roles['Most Sold Products'] &&
              (roles['Most Sold Products'].type === "Edit" )
              ? <MostSoldProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_mostSoldProducts/:id"
          element={
            roles['Most Sold Products'] &&
              (roles['Most Sold Products'].type === "Edit" || roles['Most Sold Products'].type === "View")
              ? <MostSoldProducts  />  
              : <ErrorNotFound/>
          }
        />

<Route exact
       path="/assetMaintenance"
      element={
              roles['Asset Maintenance'] &&
                (roles['Asset Maintenance'].type === "Edit" || roles['Asset Maintenance'].type === "View")
                ? <AssetMaintenances/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_assetMaintenance"
          element={
            roles['Asset Maintenance'] &&
              (roles['Asset Maintenance'].type === "Edit" )
              ? <AssetMaintenances/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_assetMaintenance/:id"
          element={
            roles['Asset Maintenance'] &&
              (roles['Asset Maintenance'].type === "Edit" )
              ? <AssetMaintenances/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_assetMaintenance/:id"
          element={
            roles['Asset Maintenance'] &&
              (roles['Asset Maintenance'].type === "Edit" || roles['Asset Maintenance'].type === "View")
              ? <AssetMaintenances  />  
              : <ErrorNotFound/>
          }
        />

<Route exact
       path="/consumedProducts"
      element={
              roles['Consumed Products'] &&
                (roles['Consumed Products'].type === "Edit" || roles['Consumed Products'].type === "View")
                ? <ConsumedProducts/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_consumedProducts"
          element={
            roles['Consumed Products'] &&
              (roles['Consumed Products'].type === "Edit" )
              ? <ConsumedProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_consumedProducts/:id"
          element={
            roles['Consumed Products'] &&
              (roles['Consumed Products'].type === "Edit" )
              ? <ConsumedProducts/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_consumedProducts/:id"
          element={
            roles['Consumed Products'] &&
              (roles['Consumed Products'].type === "Edit" || roles['Consumed Products'].type === "View")
              ? <ConsumedProducts  />  
              : <ErrorNotFound/>
          }
        />

      {/* <Route exact
        id="employee"
        path="/employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees />
            : <ErrorNotFound />
        } />

      <Route
        id="create-employee"
        path="/create_employees"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees/>
            : <ErrorNotFound />
        }
      />

      <Route
        id="edit-employee"
        path="/edit_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit")
            ? <Employees  />
            : <ErrorNotFound />
        }
      />

      <Route
        key="view-employee"
        path="/view_employees/:id"
        element={
          roles['Employees'] &&
            (roles['Employees'] === "Edit" || roles['Employees'] === "View")
            ? <Employees  />
            : <ErrorNotFound />
        }
      /> */}

      {/* <Route path="/settings" element={Settings} /> */}
      {/* <Route path="/adminSettings" element={AdminSettings} /> */}
      <Route exact
       path="/assets"
      element={
              roles['Assets'] &&
                (roles['Assets'].type === "Edit" || roles['Assets'].type === "View")
                ? <Assets/>
                  : <ErrorNotFound/>
                } />

          <Route
          path="/create_assets"
          element={
            roles['Assets'] &&
              (roles['Assets'].type === "Edit" )
              ? <Assets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/edit_assets/:id"
          element={
            roles['Assets'] &&
              (roles['Assets'].type === "Edit" )
              ? <Assets/>  
              : <ErrorNotFound/>
          }
        />

          <Route
          path="/view_assets/:id"
          element={
            roles['Assets'] &&
              (roles['Assets'].type === "Edit" || roles['Assets'].type === "View")
              ? <Assets  />  
              : <ErrorNotFound/>
          }
        />
      <Route
        path="/adminSettings"
        element={
          config.displaySettings
            ? <AdminSettings />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/uploads"
        element={
          roles["Upload Histories"] &&
            (roles["Upload Histories"]?.type === "Edit" || roles["Upload Histories"]?.type === "View")
            ? <Uploads />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/activity"
        element={
          roles["Activity"] &&
            (roles["Activity"]?.type === "Edit" || roles["Activity"]?.type === "View")
            ? <Activities />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/roles"
        element={
          roles["Roles"] &&
            (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_roles"
        element={
          roles["Roles"] &&
            (roles["Roles"] ?.type=== "Edit")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"]?.type === "Edit")
            ? <Roles  />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_roles/:id"
        element={
          roles["Roles"] &&
            (roles["Roles"]?.type === "Edit" || roles["Roles"]?.type === "View")
            ? <Roles />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/emailStatuses"
        element={
          roles["Email Statuses"] &&
            (roles["Email Statuses"]?.type === "Edit" || roles["Email Statuses"]?.type === "View")
            ? <EmailStatus />
            : <ErrorNotFound />
        }
      />

      <Route
        path="/templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit" || roles["Email Templates"]?.type === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/create_templates"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit")
            ?<EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/edit_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit")
            ?  <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      <Route
        path="/view_templates/:id"
        element={
          roles["Email Templates"] &&
            (roles["Email Templates"]?.type === "Edit" || roles["Email Templates"]?.type === "View")
            ? <EmailTemplate />
            : <ErrorNotFound />
        }
      />
      {/* <Route path="/uploads" element={Uploads} />
    <Route path="/activities" element={Activities} />
    <Route path="/roles" element={Roles} />
    <Route path="/templates" element={EmailTemplate} /> */}
    </Routes >
    <Outlet />
    </>
    )
  }
}

const Account = () => (
  <Routes>
    <Route path="/changepassword" element={<ChangePassword />} />
    <Route path="/profile" element={<Profile />} />
    
  </Routes>
  
);

const WrappedRoutes = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <h1>Hello</h1>
      <Layout />
      <div className="container__wrap">
        <Routes>
          {/* <Route path="/" element={<Account />} /> */}
          <Route path="/*" index={true} element={<Tables />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/home" element={<Home />} />
        </Routes>

      </div>
      <Outlet />
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const Router = () => (
  <MainWrapper>
    <main>
      <Routes>
        <Route path="/changeRecoverPassword/:enEmail" element={<ChangeRecoverPassword />} />
        <Route path="/loginChangePassword" element={<LoginChangePassword />} />
        <Route exact path="/" element={<Landing />} />
        <Route path="/log_in" element={<Landing />} />
        <Route path="/logout" element={<Landing />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        <Route path="/*" element={<WrappedRoutes />}  />
        
        <Route element={<ErrorNotFound />} />


      </Routes>
      <Outlet />
    </main>
  </MainWrapper>
);

export default Router;
