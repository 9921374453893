import React, {useEffect, useState} from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [isValidate,setIsValidate] = useState(false);
    const [noData,setNoData] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [disable,setDisable] = props.disable ? useState(true) : props.item.hasDependencyField ? useState(true) :useState(false); 
    const [message,setMessage] = useState('');
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    let parentField = props.watch && props.item.hasDependencyField ? props.watch(props.item.hasDependencyField) : '';
    useEffect(() => {
        if(props.item.hasDependencyField && !props.disable){
            if(parentField){
                setDisable(false);
                setErrorMessage('');
            } else {
                setDisable(true);
            }
            props.setValue(props.field.name,null);
        }
        setTimeout(() => {
            getAllSuggestions(null,'initialSuggestions');
            if(!props.getValues(props.item.hasDependencyField)){
                props.setValue(props.field.name,null);
            }
        }, 100);
      }, [parentField]);

   const onSelectRecord = (e)  => {       
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');
        if (props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            this.props.handleAutoCompleteData(e.value, name)
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }

    }

    // to remove duplicates
    const removeDuplicates = () => {
        if (this.state.filteredSuggestions) {
            setSuggestions(this.state.filteredSuggestions);
        }
    }

    const getAllSuggestions = (event,type) => {
        let url;
        // this.setState({
        //     isValidate: false,
        //     noData: false,
        //     errorMessage: ''
        // });
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        let query = '';

        if(props.item.hasDependencyField){
            if(props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]){
                filterCriteria['criteria'].push({
                    "key":props.item.fieldForKey,
                    "value":props.getValues(props.item.hasDependencyField)["_id"],
                    "type":"eq"
                })
            } else {
                setNoData(true);
                setFilteredSuggestions([]);
                if(type !== 'initialSuggestions') setErrorMessage('Please select the ' + props.item.hasDependencyField[0].toUpperCase() +  props.item.hasDependencyField.slice(1));
                return
            }
        }
        if(props?.item?.customQuery && props?.item?.customQuery?.length > 0){
            props.item.customQuery?.map( field => {
                query +=field + '='+ (props.getValues(field)?.['_id'] ? props.getValues(field)?.['_id'] : props.getValues(field)) + '&'
            })
        }
        if (event && event.query) {
            filterCriteria['criteria'].push({
                "key": props.searchField,
                "value": event.query,
                "type": "regexOr"
            });
        };

        if(props.searchApi?.toLowerCase() === 'racks') {
            filterCriteria['criteria'].push({
                "key": "status",
                "value": "Active",
                "type": "eq"
            });
        }

        let apiUrl = props.searchApi?.toLowerCase();
        if(query){
            url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}&${query}`;
        } else {
            url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        }
        return fetch('GET', url)
            .then((response) => {
                if (response) {
                    let dropdownData = [];
                    apiUrl = props?.item?.customResopnseKey ? props?.item?.customResopnseKey : apiUrl;

                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                        dropdownData = response[apiUrl];
                    }
                    if(response[apiUrl] && response[apiUrl].length === 1 && !props.item?.doNotSelectAutomatically){
                        props.setValue(props.field.name,response[apiUrl][0]);
                        if(field.name !== 'destinationRack') setDisable(true);
                        else setMessage('You have only one Destination rack.')
                    } else {
                        setMessage('')
                    }
                    if (dropdownData && dropdownData.length == 0) {                       
                        setFilteredSuggestions([]);
                        setNoData(true);
                    } else {
                        setSuggestions(dropdownData);
                    }
                }
            }).catch((err) => {
                return err;
            });
    }

   const setSuggestions = async (dropdownData) => {
        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        setFilteredSuggestions(dropdownData);
    }

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

      const itemTemplate = (item) => {
        if (props.customTemplateName && item[props.customTemplateName]) {
            return (
                <div className="country-item">
                    <div><span>{item[props.searchField]}</span></div>
                    <div style={{ fontSize: '10px' }}>{item[props.customTemplateName]}</div>
                </div >
            );
        } else {
            return (
                <div className="country-item">
                    <div>{item[props.searchField] }</div>
                </div>
            );
        }
    };

    const onSelectOption = (e) => {
        if (!props.getValues(props.item.name)) {
            props.setValue(props.item.name, e.value)
        }
    }

    return (
        <div className="flex flex-column">

            <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <AutoComplete
                inputId={field.name}
                value={field.value}
                onChange={field.onChange}
                // inputRef={field.ref}
                disabled={disable}
                suggestions={filteredSuggestions}
                completeMethod={getAllSuggestions}
                placeholder={field.value == undefined || field.value.length == 0 ? placeholder : ''}
                field={props.searchField}
                dropdown={true}
                onSelect={onSelectOption}
                multiple={props.multiple}
                selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}  
                itemTemplate={props.showItemTemplate ? itemTemplate : false}
                />

        <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        {errorMessage && <small className="text-danger">{errorMessage}</small>}
        {message && <small className="text-primary">{message}</small>}
        </div>
    )

}

export default DoAutoCompleteField;