import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Facilities from './components/Facilities';

const facilities = (props,{ t }) => (
  <Container>
    <Facilities {...props} />
  </Container>
);

facilities.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(facilities);
