import React, { useEffect, useRef, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "primereact/button";
// config file

const InventoryByRacks = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState()
  let getDataFromServerRef = useRef();
  const [ openFormModal, setopenFormModal] = useState(false);
  const [actionApiUrls, setActionApiUrls] = useState('');
  const [actionModalHeaders, setActionModalHeaders] = useState('');
  const [customActionType, setCustomActionType] = useState('');

  const params = useParams();

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [];

  useEffect(() => {
    let screenPermissions =
      RolePermissions.screenPermissions("Inventory By Racks");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: false,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "facility",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Product Name",
        derivedValue: "productName=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1018,
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "productName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Cabinet",
        label: "Cabinet",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Cabinet",
        derivedValue: "cabinents=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1009,
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "cabinents",
        searchApi: "cabinets",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "cabinents",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Racks",
        label: "Racks",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Racks",
        derivedValue: "racks=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        textAlign: "left",
        show: true,
        field: "racks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "productQuantityBeingAdded",
        type: "number",
        placeholder: "Quantity",
        label: "Quantity",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Quantity",
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "productQuantityBeingAdded",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        showZero : true,
        fieldType : 'number',
      },
      
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: false,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        disabled: true,
        show: false,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: false,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 80,
        show: true,
        field: "addConsumeActions",
        header: "Actions",
        label: "ActionsButton",
        fieldType: "customType",
        customType:customType,
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customItemTemplate : true,
        customTemplateName : 'facilityEmail'
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Select Cabinet",
        label: "Cabinets",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "cabinents=cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Select Rack",
        label: "Racks",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'cabinents',
        hasDependency: "true",
      },
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Select Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
        prepopulateField : 'productName',
        prepopulateFieldAccessName : 'description',
      },
      {
        name: "productQuantityBeingAdded",
        type: "text",
        placeholder: "Product Quantity Being Added",
        label: "Product Quantity Being Added",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        label: "Expiration Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "date",
        show: true,
        showOrHideFields: [],
        fieldName: "expirationDate",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "requiredInventory",
        type: "text",
        placeholder: "Required Inventory",
        label: "Required Inventory",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "requiredInventory=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "requiredInventory",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        disabled: true,
        show: true,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };


  const getActionFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Select Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeInMinus : true,
      },
      {
        name: "lotId",
        type: "relateAutoComplete",
        placeholder: "Select Lot Id",
        label: "Lot Id",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "lotId",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Inventories",
        searchApi: "inventories/getLotIds",
        show: true,
        showOrHideFields: [],
        fieldName: "lotId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeInPlus : true,
        customQuery : ['facility','productName','racks'],
        customResopnseKey : 'lotIds'
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customItemTemplate : true,
        customTemplateName : 'facilityEmail',
        disable : true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Select Cabinet",
        label: "Cabinets",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
        disable : true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Select Rack",
        label: "Racks",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'cabinents',
        hasDependency: "true",
        disable : true,
      },
      {
        name: "productQuantityBeingAdded",
        type: "number",
        placeholder: "Quantity",
        label: "Quantity",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "productQuantityBeingAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "productQuantityBeingAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeSetValue : true,
        showCountMessage : true,
      },
      {
        name: "expirationDate",
        type: "date",
        placeholder: "Expiration Date",
        label: "Expiration Date",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "expirationDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "date",
        show: true,
        showOrHideFields: [],
        fieldName: "expirationDate",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeInMinus : true,
      },
      
      {
        name: "consumeType",
        type: "dropDown",
        placeholder: "Consume Type",
        label: "Consume Type",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Consume Type",
        derivedValue: "consumeType=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "consumeType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Sold", value: "Sold", color: "success" },
          { label: "Expired", value: "Expired", color: "danger" },
          { label: "Damaged", value: "Damaged", color: "warning" },
          { label: "Others", value: "Others", color: "secondary" },
        ],
        filterElement: [
          { label: "Sold", value: "Sold", color: "success" },
          { label: "Expired", value: "Expired", color: "danger" },
          { label: "Damaged", value: "Damaged", color: "warning" },
          { label: "Others", value: "Others", color: "secondary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "consumeType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        excludeInPlus : true,
        value : 'Sold',
        dependent:[{"Others": ["note"]}],
        required : true,
      },
      {
        name: "note",
        type: "text",
        placeholder: "Note",
        label: "Note",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        required : true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        excludeInPlus : true,
      },
    ];
  };
  const getMoveToRackFormFields = () => {
    return [
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Select Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        disable : true,
      },
      {
        name: "lotId",
        type: "relateAutoComplete",
        placeholder: "Select Lot Id",
        label: "Lot Id",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "lotId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "lotId",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Inventories",
        searchApi: "inventories/getLotIds",
        show: true,
        showOrHideFields: [],
        customQuery : ['facility','productName','racks'],
        customResopnseKey : 'lotIds',
        fieldName: "lotId",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        customItemTemplate : true,
        customTemplateName : 'facilityEmail',
        disable : true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Select Cabinet",
        label: "Cabinets",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
        disable : true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Select Rack",
        label: "Racks",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        show: false,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'cabinents',
        hasDependency: "true",
        disable : true,
      },  
      {
        name: "destinationCabinet",
        type: "relateAutoComplete",
        placeholder: "Select Destination Cabinet",
        label: "Destination Cabinet",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "destinationCabinet=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "destnationCabinet",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'facility',
        hasDependencyField : 'facility',
        hasDependency: "true",
      },
      {
        name: "destinationRack",
        type: "relateAutoComplete",
        placeholder: "Select Destination Rack",
        label: "Destination Rack",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "destinationRack=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "destinationRack",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        fieldForKey : 'cabinet',
        hasDependencyField : 'destinationCabinet',
        hasDependency: "true",
      },
    ];
  };
  const submit = async (item) => {
    setIsOpenFormModal(true);
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "facility",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Product",
        label: "Product",
        width: "150px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Product",
        derivedValue: "product=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "product",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1018,
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "product",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinet",
        type: "relateAutoComplete",
        placeholder: "Cabinet",
        label: "Cabinet",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Cabinet",
        derivedValue: "cabinet=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinet",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1009,
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "cabinet",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Racks",
        label: "Racks",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Racks",
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "racks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "quantity",
        type: "number",
        placeholder: "Quantity",
        label: "Quantity",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Quantity",
        derivedValue: "quantity=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "quantity",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "quantity",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.inventoryByRacks}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };
  const closeOrderFormModal = () => {
    setopenFormModal(false);
    setCustomActionType('');
  }

  const getFilteredFormFields = () =>{
    if(customActionType === 'plus') {
      return getActionFormFields().filter( field => !field.excludeInPlus);
    } else if(customActionType === 'minus'){
      return getActionFormFields().filter( field => !field.excludeInMinus);
    } else {
      return getMoveToRackFormFields();
    }
  }
  const getFormModal = () => {
    return <FormModal
        openFormModal={openFormModal}
        tableRowData={selectedRowData}
        closeFormModal={closeOrderFormModal}
        type={actionModalHeaders}
        tablefieldsToShow={getTableFields}
        originalTableFields={getTableFields}
        formType={'add'}
        formFields={() =>getFilteredFormFields()}
        filterCriteria={{}}
        getDataFromServer={getDataFromServerRef.current}
        editRequired={true}
        apiUrl={actionApiUrls}
        routeTo={'inventoryByRacks'}
        displayViewOfForm={'modal'}
        entityType={'inventoryByRacks'}
        selectedId={selectedRowData._id}
        setValuesWhileAdd={true}
        addDefaultFields={ customActionType === 'minus' ? { type : 'sub'} : null}
        formHeader={actionModalHeaders}
        watchList={customActionType === 'minus' ? ['facility','productName','lotId','cabinents','racks'] : null}
      />
  }

  function customType(rowData,column,getDataFromServer) {
    if(column.field === 'addConsumeActions'){
      return (
        <div className='row'>
          <div style={{display : 'flex', justifyContent:'space-around' }}>
          <div style={{ backgroundColor : 'red'}} className="custom-acton-button">
          <FontAwesomeIcon
                className='genderIconAlignment'
                color='white'
                icon='minus'
                title='Remove Quantity from Inventory'
                data-toggle="tool-tip"
                style={{ color: '#fff', width: '20', cursor: 'pointer' }}
                onClick={() =>  {
                  setCustomActionType('minus');
                  getDataFromServerRef.current =  getDataFromServer
                  setActionApiUrls(apiCalls.inventries);
                  setActionModalHeaders('Remove Quantity From Inventory');
                  setSelectedRowData(rowData);
                  setopenFormModal(true);
                  }
                } />
          </div>
           <div style={{backgroundColor : 'green'}} className="custom-acton-button">
           <FontAwesomeIcon
              className='genderIconAlignment'
              color='#fff'
              icon='plus'
              data-toggle="tool-tip"
              title='Add Quantity to Inventory'
              style={{ color: '#fff', cursor: 'pointer', width : '20' }}
              onClick={() => {
                setCustomActionType('plus');
                setActionApiUrls(apiCalls.inventries);
                getDataFromServerRef.current =  getDataFromServer
                setActionModalHeaders('Add Quantity to Inventory');
                setSelectedRowData(rowData);
                setopenFormModal(true);
              }
              } />
           </div>
           <div style={{backgroundColor : 'blue'}} className="custom-acton-button">
           <FontAwesomeIcon
              className='genderIconAlignment'
              color='#fff'
              icon='arrow-right'
              data-toggle="tool-tip"
              title='Move The Rack'
              style={{ color: '#fff', cursor: 'pointer', width : '20' }}
              onClick={() => {
                if(rowData?.cabinents) rowData.destinationCabinet = JSON.parse(JSON.stringify(rowData.cabinents));
                setCustomActionType('Move Rack')
                setActionApiUrls('inventories/moveCabinetsAndRacks')
                getDataFromServerRef.current =  getDataFromServer
                setActionModalHeaders('Move To Another Rack')
                setSelectedRowData(rowData);
                setopenFormModal(true);
              }
              } />
           </div>
          </div>
        </div >
      )
    }
  }

  return (
    <span>
      {openFormModal && selectedRowData && getFormModal()}
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={false && isEditPermission}
          deleteRequired={false && isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={false && isEditPermission}
          actionTypes={actionTypes}
          sample={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={false}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.inventoryByRacks}
          globalSearch={
            "Facility/Cabinet/Racks/Product Name"
          }
          displayName="Inventory By Racks"
          type="InventoryByRacks"
          routeTo={apiCalls.inventoryByRacks}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.inventoryByRacks?.toLowerCase()}
          apiUrl={'inventories/'+apiCalls.inventoryByRacks}
          selectedId={params.id}
          formUrl={apiCalls.inventries}
          formHeader={'Add Product To Inventory'}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="inventoryByRacks"
          apiUrl={apiCalls.inventoryByRacks}
        />
      ) : null}
    </span>
  );
};

export default InventoryByRacks;
