import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Cabinets = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);
  const [racksOptions, setRacksOptions] = useState([]);

  const params = useParams();
  let filterQueryParam ;
  const query = new URLSearchParams(location.search);

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  if(query.get('searchId') && query.get('searchName')){
    filterQueryParam = { searchId : query.get('searchId'), searchName : query.get('searchName')}
  }
  useEffect(() => {
    setOptionsToRacks();
    let screenPermissions = RolePermissions.screenPermissions("Cabinets");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "left",
        show: true,
        field: "facility",
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "displayName",
        type: "text",
        placeholder: "Cabinet Name",
        label: "Cabinet Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Cabinet Name",
        derivedValue: "cabinetName=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinetName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "displayName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "building",
        type: "text",
        placeholder: "Building",
        label: "Building",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Building",
        derivedValue: "building=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "building",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "building",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "area",
        type: "text",
        placeholder: "Area",
        label: "Area",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Area",
        derivedValue: "area=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "area",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "area",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floor",
        type: "text",
        placeholder: "Floor",
        label: "Floor",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Floor",
        derivedValue: "floor=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "floor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "floor",
        showOrHideFields: [],
        fieldName: "floor",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "room",
        type: "text",
        placeholder: "Room",
        label: "Room",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Room",
        derivedValue: "room=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "room",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: false,
        field: "room",
        showOrHideFields: [],
        fieldName: "room",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "noOfRacks",
        type: "number",
        placeholder: "No Of Racks",
        label: "No Of Racks",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "No Of Racks",
        derivedValue: "noOfRacks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "noOfRacks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "racks",
        textAlign: "Center",
        show: true,
        field: "noOfRacks",
        showOrHideFields: [],
        fieldName: "noOfRacks",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'redirectable',
        redirecTo : 'racks',
        redirectFilterField : 'cabinet',
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: false,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Created",
        derivedValue: "created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Updated",
        derivedValue: "updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Select Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        show: true,
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
        customItemTemplate : true,
        customTemplateName : 'facilityEmail'
      },
      {
        name: "cabinetName",
        type: "text",
        placeholder: "Cabinet Name",
        label: "Cabinet Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 1,
        derivedValue: "cabinetName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "cabinetName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "building",
        type: "text",
        placeholder: "Building",
        label: "Building",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 4,
        derivedValue: "building=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "building",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "area",
        type: "text",
        placeholder: "Area",
        label: "Area",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 5,
        derivedValue: "area=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "area",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "floor",
        type: "text",
        placeholder: "Floor",
        label: "Floor",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 3,
        derivedValue: "floor=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "floor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "floor",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "room",
        type: "text",
        placeholder: "Room",
        label: "Room",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 2,
        derivedValue: "room=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "room",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "room",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "noOfRacks",
        type: "number",
        placeholder: "How many racks per a cabinet",
        label: "How many racks per a cabinet",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "noOfRacks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "noOfRacks",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "racks",
        show: true,
        showOrHideFields: [],
        fieldName: "noOfRacks",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "racks",
        type: "multiSelectApiOptions",
        placeholder: "racks",
        label: "Racks",
        width: "130px",
        addFormOrder: 45,
        editFormOrder: 45,
        derivedValue: "document=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        searchApi : 'racks',
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        fieldType: "dropDown",
        dependent: [],
        optionLabel : 'rackName',
        optionValue : '_id',
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
        isMultiSelect: true,
        filterById : true,
        filterIdName : 'cabinet',
        filterKeyName : 'rackState' ,
        filterValue : 'Filled'
      },
    ];
  };

  function customOptions(params) {
    return racksOptions;
  }

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "Center",
        show: true,
        field: "facility",
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinetName",
        type: "text",
        placeholder: "Cabinet Name",
        label: "Cabinet Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Cabinet Name",
        derivedValue: "cabinetName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "cabinetName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "cabinetName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "building",
        type: "text",
        placeholder: "Building",
        label: "Building",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Building",
        derivedValue: "building=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "building",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "building",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "area",
        type: "text",
        placeholder: "Area",
        label: "Area",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Area",
        derivedValue: "area=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "area",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "area",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "floor",
        type: "text",
        placeholder: "Floor",
        label: "Floor",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Floor",
        derivedValue: "floor=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "floor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "floor",
        showOrHideFields: [],
        fieldName: "floor",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "room",
        type: "text",
        placeholder: "Room",
        label: "Room",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Room",
        derivedValue: "room=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "room",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "room",
        showOrHideFields: [],
        fieldName: "room",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "weeklyNotifiedOn",
        type: "dropDown",
        placeholder: "Select Day",
        label: "Weekly Notified On",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Weekly Notified On",
        derivedValue: "weeklyNotifiedOn=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "weeklyNotifiedOn",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Sunday", value: "Sunday", color: "primary" },
          { label: "Monday", value: "Monday", color: "primary" },
          { label: "Tuesday", value: "Tuesday", color: "primary" },
          { label: "Wednesday", value: "Wednesday", color: "primary" },
          { label: "Thursday", value: "Thursday", color: "primary" },
          { label: "Friday", value: "Friday", color: "primary" },
          { label: "Saturday", value: "Saturday", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: false,
        field: "weeklyNotifiedOn",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Created",
        derivedValue: "created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Updated",
        derivedValue: "updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "noOfRacks",
        type: "number",
        placeholder: "No Of Racks",
        label: "No Of Racks",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "No Of Racks",
        derivedValue: "noOfRack=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "noOfRacks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "racks",
        textAlign: "Center",
        show: true,
        field: "noOfRacks",
        showOrHideFields: [],
        fieldName: "noOfRacks",
        fieldType: "number",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  function setOptionsToRacks(){
    let processedData = [];
      fetchMethodRequest('GET',apiCalls.racks).then( async (response) => {
        if(response && response.racks){
          // processedData = response.racks.map( value => { label : value.rackName})
          console.log('response', response);
          setRacksOptions(response.racks);
        }
      })
  }
  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.cabinets}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={false}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.cabinets}
          globalSearch={
            "Facility/Cabinet Name"
          }
          displayName="Cabinets"
          type="Cabinets"
          routeTo={apiCalls.cabinets}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.cabinets.toLowerCase()}
          apiUrl={apiCalls.cabinets}
          selectedId={params.id}
          filterQueryParam = {filterQueryParam}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="cabinets"
          apiUrl={apiCalls.cabinets}
        />
      ) : null}
    </span>
  );
};

export default Cabinets;
