import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Helps from './components/Helps';

const helps = (props,{ t }) => (
  <Container>
    <Helps {...props} />
  </Container>
);

helps.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(helps);
