import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StorageUnitItems from './components/StorageUnitItems';

const storageUnitItems = (props,{ t }) => (
  <Container>
    <StorageUnitItems {...props} />
  </Container>
);

storageUnitItems.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(storageUnitItems);
