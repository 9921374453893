import React from 'react';
import dateFormats from '../../UI/FormatDate/formatDate';
import config from '../../../config/config';
import moment from 'moment';
import 'moment-timezone'

function FunnelFilter(filterEvent,fieldTypeObj,defaultSortField,sortOrd,sortFld) {

  const formFilter = () => {
    let currentPage = 1;
    if (filterEvent && filterEvent.first && filterEvent.rows) {
      currentPage = filterEvent.first / filterEvent.rows + 1;
    }

    let filterCriteria = {};
    let criteria = [];

    filterCriteria['page'] = currentPage;
    filterCriteria['limit'] = filterEvent.rows ? filterEvent.rows : 20;
    filterCriteria['sortfield'] = sortFld && sortFld.length ? sortFld : 'created' ;

    if(sortOrd && sortOrd!= undefined) {
      if(sortOrd == 1){
        filterCriteria['direction'] = 'asc';
      }
      if(sortOrd == 0){
        filterCriteria['direction'] = 'desc';
      }
    }

    if (filterEvent && filterEvent.filters) {
      let filters = Object.keys(filterEvent.filters);

      if (filters && filters.length > 0) {
        filters.forEach(filter => {
          let filterArray = filterEvent.filters[filter]?.constraints;
          if (filterArray && filterArray.length > 0) {
            filterArray.forEach(element => {

              if(element.value!=null && (element.matchMode=='dateIs' || element.matchMode=='dateBefore' || element.matchMode=='dateAfter')){
                let dateFilters = formatDateFilters(element, filter);
                dateFilters.forEach((dateFilter) => {
                  criteria.push(dateFilter);
                });
              }

              if (element.value && filter && fieldTypeObj && !(element.matchMode == 'dateIs' || element.matchMode == 'dateBefore' || element.matchMode == 'dateAfter')) {
                let isRelateAutoComplete = fieldTypeObj[filter] === "relateautocomplete";
                if (fieldTypeObj[filter] === "relateautocomplete") {
                  filter = filter + 'Search';
                }
                let valueAndType = getValueAndType(filter, fieldTypeObj, element);
                if(isRelateAutoComplete){
                  valueAndType.type = 'regexOr';
                }
                if (Array.isArray(valueAndType)) {
                  valueAndType.forEach(x => criteria.push({ key: filter, value: x.value, type: x.type }));
                } else {
                  criteria.push({ key: filter, value: valueAndType.value, type: valueAndType.type });
                }
              }

            });
          }
        });
      }
    }
    filterCriteria['criteria'] = criteria;
    return filterCriteria;
  };

  const formatDateFilters = (element, field) => {

    //for created and updated date fields
    if (field == "created" || field == "updated") {
      const val = element.value[0];
      const inputDate = dateFormats.formatDate(val, config.dayYearDateFormat);
      const appAfter = inputDate + 'T23:59:59Z'
      const appBefore = inputDate + 'T00:00:00Z'
      const previousDate = getPreviousDate(inputDate);
      const inputDate2 =dateFormats.formatDate(element.value[1], config.dayYearDateFormat);

      if (element.matchMode == 'dateIs') {
        if(element.value[1] != null){
          return [{ key: field, value: `${inputDate}T00:00:00Z`, type: 'gte' }, { key: field, value: `${inputDate2}T23:59:59Z`, type: 'lte' }]
        }
        else{
          return [{ key: field, value: appBefore, type: 'gte' }, { key: field, value: appAfter, type: 'lte' }]
        }
      }
      if(element.matchMode == 'dateAfter'){
        if(element.value[1] != null){
          return [{ key: field, value: `${inputDate2}T23:59:59Z`, type: 'gte' }]
        }
        else{
        return[{key : field, value:`${inputDate}T23:59:59Z`, type: 'gt'}]
        }
      }
      if(element.matchMode == 'dateBefore'){
        if(element.value[1] != null){
          return [{ key: field, value: `${inputDate}T00:00:00Z`, type: 'lt' }]
        }
        else{
        return[{key : field, value:`${previousDate}T23:59:59Z`, type: 'lte'}]
        }
      }

    }

    //other date fields
    else {
      //when a range is selected
      if (element.value.length == 2 && element.value[1] != null) {
        const initialDate =  moment(element.value[0]).tz(moment.tz.guess()).toISOString()
        const inputDateForNext = moment(element.value[1]).tz(moment.tz.guess()).add(1, 'days').toISOString();
        if (element.matchMode == 'dateIs') {
          return [
            { key: field, value: initialDate, type: 'dategte' },
            { key: field, value: inputDateForNext, type: 'datelte' }
          ];
        }
        if (element.matchMode == 'dateBefore') {
          return [{ key: field, value: initialDate, type: 'datelte' }]
        }
        if (element.matchMode == 'dateAfter') {
          return [{ key: field, value: inputDateForNext, type: 'dategt' }]
        }
      }
      //when a single date is selected
      else {
        const val = element.value[0];
        const nextDay = moment(val).tz(moment.tz.guess()).add(1, 'days').toISOString();
        const previousSearchDate = moment(val).tz(moment.tz.guess()).toISOString();

        if (element.matchMode == 'dateIs') {
          return [{ key: field, value: previousSearchDate, type: 'dategte' }, { key: field, value: nextDay, type: 'datelt' }]
        }
        if (element.matchMode == 'dateBefore') {
          return [{ key: field, value: previousSearchDate, type: 'datelte' }]
        }
        if (element.matchMode == 'dateAfter') {
          return [{ key: field, value: nextDay, type: 'dategt' }]
        }
      }
    }

  }

  const getPreviousDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      date.setDate(date.getDate() - 1);

      // Format the date in 'YYYY-MM-DD' format
      const formattedDate = date.toISOString().split('T')[0];

      return formattedDate;
    } else {
      return null; // or handle the case where date is not provided
    }
  };

  const getValueAndType = (field, fieldTypeObj, filterObj) => {
    let type = filterObj?.matchMode;
    if (type) {
      switch (type) {
        case 'equals':
          type = 'eq';
          break;
        case 'startsWith':
          type = 'sw';
          break;
        case 'endsWith':
          type = 'ew';
          break;
        case 'notEquals':
          type = 'ne'
          break;
        // case 'dateIsNot':
        case 'notContains':
          type = 'ne';
          break;
        case 'lt':
          type = 'lt';
          break;
        case 'gt':
          type = 'gt';
          break;
        case 'gte':
          type = 'gte';
          break;
        case 'lte':
          type = 'lte';
          break;

        default:
          type = 'regexOr';
      }
    }
    if (fieldTypeObj[field]) {

      if (fieldTypeObj[field] == 'dropdown' && filterObj.value.length > 0) {
        const valArray=filterObj.value
        return { value: valArray, type: 'in' };
      }
      else if (fieldTypeObj[field] == 'radio' && filterObj.value.length > 0) {
        const valArray=filterObj.value
        return { value: valArray, type: 'in' };
      }
      else {
        return { value: filterObj.value, type: type };
      }
    }
    return { value: filterObj.value, type: type };
  };

  return formFilter();

}

export default FunnelFilter;
